import React, { useState, useEffect, useRef } from 'react'
import { useInView  } from 'react-spring';
const InView = ({ children, className, viewFn } ) => {
  const [ref, inView] = useInView();
  useEffect(()=> {
    viewFn(inView)
}, [inView])
  return (
    <div ref={ref} className={className}>
      { children }
    </div>
  )
}
export default InView;