import React, { useState, useEffect } from 'react'
import { Pagination, Carousel } from 'antd';
import { throttle } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import { useMyContext } from '../../layouts/index';
import { useTranslation, Trans } from 'react-i18next';
import { GET_NEWS_DETAIL } from '../../services/data.js'
import { useNavigate } from 'react-router-dom';
import { getParamsUrl } from '../../utils'
import { imageBusinessPaths, LogoImg } from '../Home/exportImg.js'
const arrowLeft = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"/>
</svg>

const Info = () => {
    const sharedData = useMyContext(); //共享语言切换
    const [time, setTime] = useState('')
    const [title, setTitle] = useState('')
    const [dynamicContent , setDynamicContent ] = useState('')
    const { t, i18n } = useTranslation();
    // 小于768 切换移动端
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    })
    const navigate = useNavigate();
    const goBack = () => {
        const { pn, active } = getParamsUrl();
        navigate(`/news?pn=${pn}&active=${active}`);
    };
    useEffect(() => {
        // url获取参数查询
      let params_url = window.location.href.split('?')
      let params_arr = null
      let obj = {}
      if (params_url.length > 1) {
          params_arr = params_url[1].split('&')
          params_arr.forEach((element) => {
              let kv = element.split('=')
              obj[kv[0]] = kv[1]
          });
          console.log(obj)
          if (obj.length != 0 && obj['id'] !='') {
            fetch_get_list_detail(obj['id'])
          }
      }
    }, [sharedData['lang']])

    // 列表请求
    const fetch_get_list_detail = (id) => {
        GET_NEWS_DETAIL({ language: sharedData['lang'], id: id }).then(res => {
            if (res.error_code != 0) {
                console.log('error')
                return
            }
            console.log(res.detail)
            setTitle(res.detail['title'])
            setTime(res.detail['time'])
            setDynamicContent(res.detail['content'])
        })
    }

    return (
        <div className=''>
            {
                isDesktopOrLaptop && (
                    <div className='container'>
                        <div className='news-info'>
                            <div className='back' onClick={()=>goBack()}>{arrowLeft}&nbsp;&nbsp;&nbsp;{t('news.news_back')}</div>
                            <h4 className='news-info-title'>{title}</h4>
                            <p className='news-info-tims'>{time}</p>
                            <div dangerouslySetInnerHTML={{ __html: dynamicContent }} />
                        </div>
                    </div>
                )
            }
            {
                !isDesktopOrLaptop && (
                    <div className='container'>
                        <div className='m-news-info'>
                            <div className='m-back' onClick={()=>goBack()}>{arrowLeft}&nbsp;&nbsp;&nbsp;{t('news.news_back')}</div>
                            <h4 className='news-info-title'>{title}</h4>
                            <p className='news-info-tims'>{time}</p>
                            <div dangerouslySetInnerHTML={{ __html: dynamicContent }} />
                        </div>
                    </div>
                )
            }
        </div>
    )
}
export default Info