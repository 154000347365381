import React, { useState, useEffect } from 'react'
import { throttle } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useLocation } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next';
import { Parallax } from 'rc-scroll-anim';
import { animateScroll as scroll } from "react-scroll";
import WOW from 'wowjs';
import { imageBusinessPaths, LogoImg } from '../Home/exportImg.js'
import { STATIC_URL } from '../../ip.js'
const businessIcon1 = STATIC_URL + '/imgs/business-icon-1.png'
const Heading = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bookmark-fill" viewBox="0 0 16 16">
    <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
</svg>

const HeadingMobile = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-bookmark-fill" viewBox="0 0 16 16">
    <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
</svg>

const Business = () => {
    const [indicatorWidth, setIndicatorWidth] = useState(0)
    const [indicatorOffset, setIndicatorOffset] = useState(0)
    const [active, setActive] = useState(0)
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    let location = useLocation()
    const { search } = location
    // 小于768 切换移动端
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    })
    useEffect(() => {
        let wows = new WOW.WOW({
            boxClass: 'wow',            // 默认的WOW容器类名
            animateClass: 'animated',   // 默认的CSS动画类名
            offset: 0,                  // 触发动画的元素距离可视窗口底部的偏移量
            mobile: true,               // 在移动设备上是否启用动画
            live: true,                 // 异步加载内容时是否重新初始化WOW
            scrollContainer: null
        });
        wows.init({
            delay: '0.3s',              // 触发动画前的延时
            duration: '2s',           // 动画持续时间
        });
    }, []);
    useEffect(() => {
        updateDimensions()
        const fn =  throttle(updateDimensions, 100)
        // 添加窗口大小变化的事件监听器
        window.addEventListener('resize', fn);
        return ()=> {
            window.removeEventListener('resize', fn)
        }
    }, [ active ])
    useEffect(() => {
        // url获取参数查询
        let params_url = window.location.href.split('?')
        let params_arr = null
        let obj = {}
        if (params_url.length > 1) {
            params_arr = params_url[1].split('&')
            params_arr.forEach((element) => {
                let kv = element.split('=')
                obj[kv[0]] = kv[1]
            })
            if (obj.length != 0 && obj['type'] != '') {
                if (obj['type'] != '') {
                    handleTagActive(obj['type'])
                } else {
                    handleTagActive(0)
                }
            }
        }
    }, [search])
    const updateDimensions = () => {
        let selectedTab = ''
        if (isDesktopOrLaptop) {
            selectedTab = document.querySelectorAll('.tabs-item')[0]
        } else {
            selectedTab = document.querySelectorAll('.m-tabs-item')[0]
        }
        if (!selectedTab) return;
        let offMove = active * selectedTab.offsetWidth
        setIndicatorWidth(selectedTab.offsetWidth)
        setIndicatorOffset(offMove)
        // navigate(`/business?type=${active}`)
    }
    // 切换
    const handleTagActive = (index) => {
        setActive(index)
        let selectedTab = ''
        if (isDesktopOrLaptop) {
            selectedTab = document.querySelectorAll('.tabs-item')[index];
        } else {
            selectedTab = document.querySelectorAll('.m-tabs-item')[index];
        }
        if (!selectedTab) return;
        let offMove = index * selectedTab.offsetWidth
        setIndicatorOffset(offMove)
        navigate(`/business?type=${index}`)
    }
    //
    return (
        <div className='bg-gray' >
            {
                isDesktopOrLaptop && (
                    <div>
                        <div className='business-wrap-banner'></div>
                        <div className='container'>
                            <div className='business-tabs wow fadeInUp' id='w-bussiness'>
                                <div className='business-tabs-flex'>
                                    <div className={`tabs-item ${active == 0 ? 'active' : ''}`} onClick={() => handleTagActive(0)}>
                                        {t('business.hardcore')}
                                    </div>
                                    <div className={`tabs-item ${active == 1 ? 'active' : ''}`} onClick={() => handleTagActive(1)}>
                                        {t('business.quick')}
                                    </div>
                                    <div className={`tabs-item ${active == 2 ? 'active' : ''}`} onClick={() => handleTagActive(2)}>
                                        {t('business.golden')}
                                    </div>
                                    <div className={`tabs-item ${active == 3 ? 'active' : ''}`} onClick={() => handleTagActive(3)}>
                                        {t('business.ads')}
                                    </div>
                                </div>
                                <div
                                    className='active-border'
                                    style={{ width: indicatorWidth + 'px', transform: 'translateX(' + indicatorOffset + 'px)' }}
                                ></div>
                            </div>
                            <div className='business-tabs-connnet'>
                                <div className={`business-tabs-connnet-list ${active == 0 ? 'active' : ''}`}>
                                        <div className='connnet-list-item wow fadeInUp'>
                                            <div className='heading-icon'>
                                                {Heading}
                                            </div>
                                            <div className='left'>
                                                <h4>{t('business.hardcore_value')}</h4>
                                                <p>{t('business.hardcore_value_con')}</p>
                                            </div>
                                            <div className='right'>
                                                <img src={imageBusinessPaths['businessItem1']} alt="pic" />
                                            </div>
                                        </div>
                                        <div className='connnet-list-item wow fadeInUp'>
                                            <div className='heading-icon'>
                                                {Heading}
                                            </div>
                                            <div className='left'>
                                                <h4>{t('business.hardcore_function')}</h4>
                                                <p>{t('business.hardcore_function_con')}</p>
                                            </div>
                                            <div className='right'>
                                                <img src={imageBusinessPaths['businessItem2']} alt="pic" />
                                            </div>
                                        </div>
                                </div>
                                <div className={`business-tabs-connnet-list ${active == 1 ? 'active' : ''}`}>
                                        <div className='connnet-list-item wow fadeInUp'>
                                            <div className='heading-icon'>
                                                {Heading}
                                            </div>
                                            <div className='left'>
                                                <h4>{t('business.quick_value')}</h4>
                                                <p>{t('business.quick_value_con')}</p>
                                            </div>
                                            <div className='right'>
                                                <img src={imageBusinessPaths['businessItem3']} alt="pic" />
                                            </div>
                                        </div>
                                        <div className='connnet-list-item wow fadeInUp'>
                                            <div className='heading-icon'>
                                                {Heading}
                                            </div>
                                            <div className='left'>
                                                <h4>{t('business.quick_function')}</h4>
                                                <p>{t('business.quick_function_con')}</p>
                                            </div>
                                            <div className='right'>
                                                <img src={imageBusinessPaths['businessItem4']} alt="pic" />
                                            </div>
                                        </div>
                                </div>
                                <div className={`business-tabs-connnet-list ${active == 2 ? 'active' : ''}`}>
                                        <div className='connnet-list-item wow fadeInUp'>
                                            <div className='heading-icon'>
                                                {Heading}
                                            </div>
                                            <div className='left'>
                                                <h4>{t('business.golden_value')}</h4>
                                                <p>{t('business.golden_value_con')}</p>
                                            </div>
                                            <div className='right'>
                                                <img src={imageBusinessPaths['businessItem5']} alt="pic" />
                                            </div>
                                        </div>
                                        <div className='connnet-list-item wow fadeInUp'>
                                            <div className='heading-icon'>
                                                {Heading}
                                            </div>
                                            <div className='left'>
                                                <h4>{t('business.golden_function')}</h4>
                                                <p>{t('business.golden_function_con')}</p>
                                            </div>
                                            <div className='right'>
                                                <img src={imageBusinessPaths['businessItem6']} alt="pic" />
                                            </div>
                                        </div>
                                </div>
                                <div className={`business-tabs-connnet-list ${active == 3 ? 'active' : ''}`}>
                                     <div className='connnet-list-item wow fadeInUp'>
                                            <div className='heading-icon'>
                                                {Heading}
                                            </div>
                                            <div className='left'>
                                                <h4>{t('business.ads_introduce')}</h4>
                                                <p>{t('business.ads_introduce_con')}</p>
                                            </div>
                                            <div className='right'>
                                                <img src={imageBusinessPaths['businessItem7']} alt="pic" />
                                            </div>
                                        </div>
                                        <div className='connnet-list-item wow fadeInUp'>
                                            <div className='heading-icon'>
                                                {Heading}
                                            </div>
                                            <div className='left'>
                                                <h4>{t('business.ads_cooperate')}</h4>
                                                <p>{t('business.ads_cooperate_con')}</p>
                                            </div>
                                            <div className='right'>
                                                <img src={imageBusinessPaths['businessItem8']} alt="pic" />
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                        {active !=3 && (
                                <div className='partner'>
                                    <div className='container'>
                                        <h3 className='home-h3-title'>
                                            {t('business.business_sub')}
                                        </h3>
                                        <div className={`partner-list ${active == 0 ? 'active' : ''}`}>

                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['coolpadImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['huaweiImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['nubiaImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['lenovoImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['vivoImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['oppoImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['meizuImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['honorImg']} alt='pic' />
                                            </div>
                                        </div>
                                        <div className={`partner-list ${active == 1 ? 'active' : ''}`}>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['miImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['huaweiImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['nubiaImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['lenovoImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['vivoImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['oppoImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['meizuImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['honorImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['ydImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['zteImg']} alt='pic' />
                                            </div>
                                        </div>
                                        <div className={`partner-list ${active == 2 ? 'active' : ''}`}>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['vivoImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['oppoImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['miImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['lenovoImg']} alt='pic' />
                                            </div>
                                            <div className='partner-list-item wow fadeInUp'>
                                                    <img src={LogoImg['bbgImg']} alt='pic' />
                                            </div>
                                        </div>
                                            <div className={`partner-tel wow fadeInUp ${active == 0 ? 'active' : ''}`}>
                                                <img src={businessIcon1} alt='pic' />
                                                <h3>{t('business.hardcore_contact')}</h3>
                                            </div>
                                            <div className={`partner-tel wow fadeInUp ${active == 1 ? 'active' : ''}`}>
                                                <img src={businessIcon1} alt='pic' />
                                                <h3>{t('business.quick_contact')}</h3>
                                            </div>
                                            <div className={`partner-tel wow fadeInUp ${active == 2 ? 'active' : ''}`}>
                                                <img src={businessIcon1} alt='pic' />
                                                <h3>{t('business.golden_contact')}</h3>
                                            </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
            {
                !isDesktopOrLaptop && (
                    <div>
                        <div className='m-business-wrap-banner'></div>
                        <div className='container'>
                            <div className='m-business-tabs wow fadeInUp'>
                                <div className='m-business-tabs-flex'>
                                    <div className={`m-tabs-item ${active == 0 ? 'active' : ''}`} onClick={() => handleTagActive(0)}>
                                        {t('business.hardcore')}
                                    </div>
                                    <div className={`m-tabs-item ${active == 1 ? 'active' : ''}`} onClick={() => handleTagActive(1)}>
                                        {t('business.quick')}
                                    </div>
                                    <div className={`m-tabs-item ${active == 2 ? 'active' : ''}`} onClick={() => handleTagActive(2)}>
                                        {t('business.golden')}
                                    </div>
                                    <div className={`m-tabs-item ${active == 3 ? 'active' : ''}`} onClick={() => handleTagActive(3)}>
                                        {t('business.ads')}
                                    </div>
                                </div>
                                <div
                                    className='m-active-border'
                                    style={{ width: indicatorWidth + 'px', transform: 'translateX(' + indicatorOffset + 'px)' }}
                                ></div>
                            </div>
                            <div className='m-business-tabs-connnet'>
                                <div className={`m-business-tabs-connnet-list ${active == 0 ? 'active' : ''}`}>
                                    <div className='m-connnet-list-item wow fadeInUp'>
                                        {HeadingMobile}
                                        <div className='left'>
                                            <h4>{t('business.hardcore_value')}</h4>
                                            <p>{t('business.hardcore_value_con')}</p>
                                        </div>
                                        <div className='right'>
                                            <img src={imageBusinessPaths['businessItem1']} alt="pic" />
                                        </div>
                                    </div>
                                    <div className='m-connnet-list-item wow fadeInUp'>
                                        {HeadingMobile}
                                        <div className='left'>
                                            <h4>{t('business.hardcore_function')}</h4>
                                            <p>{t('business.hardcore_function_con')}</p>
                                        </div>
                                        <div className='right'>
                                            <img src={imageBusinessPaths['businessItem2']} alt="pic" />
                                        </div>
                                    </div>
                                </div>
                                <div className={`m-business-tabs-connnet-list ${active == 1 ? 'active' : ''}`}>
                                    <div className='m-connnet-list-item wow fadeInUp'>
                                        {HeadingMobile}
                                        <div className='left'>
                                            <h4>{t('business.quick_value')}</h4>
                                            <p>{t('business.quick_value_con')}</p>
                                        </div>
                                        <div className='right'>
                                            <img src={imageBusinessPaths['businessItem3']} alt="pic" />
                                        </div>
                                    </div>
                                    <div className='m-connnet-list-item wow fadeInUp'>
                                        {HeadingMobile}
                                        <div className='left'>
                                            <h4>{t('business.quick_function')}</h4>
                                            <p>{t('business.quick_function_con')}</p>
                                        </div>
                                        <div className='right'>
                                            <img src={imageBusinessPaths['businessItem4']} alt="pic" />
                                        </div>
                                    </div>
                                </div>
                                <div className={`m-business-tabs-connnet-list ${active == 2 ? 'active' : ''}`}>
                                    <div className='m-connnet-list-item wow fadeInUp'>
                                        {HeadingMobile}
                                        <div className='left'>
                                            <h4>{t('business.golden_value')}</h4>
                                            <p>{t('business.golden_value_con')}</p>
                                        </div>
                                        <div className='right'>
                                            <img src={imageBusinessPaths['businessItem5']} alt="pic" />
                                        </div>
                                    </div>
                                    <div className='m-connnet-list-item wow fadeInUp'>
                                        {HeadingMobile}
                                        <div className='left'>
                                            <h4>{t('business.golden_function')}</h4>
                                            <p>{t('business.golden_function_con')}</p>
                                        </div>
                                        <div className='right'>
                                            <img src={imageBusinessPaths['businessItem6']} alt="pic" />
                                        </div>
                                    </div>
                                </div>
                                <div className={`m-business-tabs-connnet-list ${active == 3 ? 'active' : ''}`}>
                                    <div className='m-connnet-list-item wow fadeInUp'>
                                        {HeadingMobile}
                                        <div className='left'>
                                            <h4>{t('business.ads_introduce')}</h4>
                                            <p>{t('business.ads_introduce_con')}</p>
                                        </div>
                                        <div className='right'>
                                            <img src={imageBusinessPaths['businessItem7']} alt="pic" />
                                        </div>
                                    </div>
                                    <div className='m-connnet-list-item wow fadeInUp'>
                                        {HeadingMobile}
                                        <div className='left'>
                                            <h4>{t('business.ads_cooperate')}</h4>
                                            <p>{t('business.ads_cooperate_con')}</p>
                                        </div>
                                        <div className='right'>
                                            <img src={imageBusinessPaths['businessItem8']} alt="pic" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            active != 3 && (
                                <div className='m-partner'>
                                    <div className='container'>
                                        <h3 className='m-home-h3-title wow fadeInUp'>
                                            {t('business.business_sub')}
                                        </h3>
                                        <div className={`m-partner-list ${active == 0 ? 'active' : ''}`}>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['coolpadImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['huaweiImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['nubiaImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['lenovoImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['vivoImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['oppoImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['meizuImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['honorImg']} alt='pic' />
                                            </div>
                                        </div>
                                        <div className={`m-partner-list ${active == 1 ? 'active' : ''}`}>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['miImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['huaweiImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['nubiaImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['lenovoImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['vivoImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['oppoImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['meizuImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['honorImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['ydImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['zteImg']} alt='pic' />
                                            </div>
                                        </div>
                                        <div className={`m-partner-list ${active == 2 ? 'active' : ''}`}>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['vivoImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['oppoImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['miImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['lenovoImg']} alt='pic' />
                                            </div>
                                            <div className='m-partner-list-item  wow fadeInUp'>
                                                <img src={LogoImg['bbgImg']} alt='pic' />
                                            </div>
                                        </div>
                                        {/* <div className='m-partner-tel'>
                                            <img src={businessIcon1} alt='pic' />
                                            <h3>合作联系：陈依 150 7984 6816</h3>
                                        </div> */}
                                        <div className={`m-partner-tel ${active == 0 ? 'active' : ''}`}>
                                            <img src={businessIcon1} alt='pic' />
                                            <h3>{t('business.hardcore_contact')}</h3>
                                        </div>

                                        <div className={`m-partner-tel ${active == 1 ? 'active' : ''}`}>
                                            <img src={businessIcon1} alt='pic' />
                                            <h3>{t('business.quick_contact')}</h3>
                                        </div>

                                        <div className={`m-partner-tel ${active == 2 ? 'active' : ''}`}>
                                            <img src={businessIcon1} alt='pic' />
                                            <h3>{t('business.golden_contact')}</h3>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            }
        </div>
    )
}
export default Business