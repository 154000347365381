import React, { useState, useEffect, useRef } from 'react'
import { Image, Form, Select, Row, Col, Empty, Spin } from 'antd';
import { useMediaQuery } from 'react-responsive'
import { Parallax } from 'rc-scroll-anim';
import WOW from 'wowjs';
import Texty from 'rc-texty';
import { useTranslation, Trans } from 'react-i18next';
import { throttle, values } from 'lodash'
import { useMyContext } from '../../layouts/index';
import { clearObj } from '../../utils';
import { GET_JOBS_PHOTO, GET_JOBS_LIST } from '../../services/data.js'
import { imageJobPaths } from '../Home/exportImg.js'
import { animateScroll as scroll, scroller, Element  } from 'react-scroll';
import animType from 'rc-texty/lib/animTypes';
import 'rc-texty/assets/index.css';
const InlineBi = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
</svg>

const { Option } = Select
const Jobs = () => {
    const sharedData = useMyContext(); //共享语言切换
    const [indicatorWidth, setIndicatorWidth] = useState(0)
    const [indicatorOffset, setIndicatorOffset] = useState(0)
    const [visible, setVisible] = useState(-1)  // 招聘信息 收起与展开
    const [visibleHeight, setVisibleHeight] = useState(0)  // 招聘信息 收起与展开
    const [visibleMobile, setVisibleMobile] = useState(-1)  // 招聘信息 收起与展开  移动端
    const [visibleHeightMobile, setVisibleHeightMobile] = useState(0)  // 招聘信息 收起与展开  移动端
    const [form] = Form.useForm();
    const refs = useRef(null)
    const [ active, setActive ] = useState(1) 
    const [offsetX, setOffsetX] = useState({
        gz: {
            style: '0px, 100px',
            class: ''
        },
        bj: {
            style: '0px, 0px',
            class: 'active'
        },
        sz: {
            style: '0px, 100px',
            class: ''
        }
    });
    const [loading, setLoading] = useState(false)
    const [jobsInfo, setJobsInfo] = useState({
        list: [], cityList: [], positionList: []
    })
    const { t, i18n } = useTranslation();
    // 小于768 切换移动端
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    })

    useEffect(() => {
        const fn = throttle(()=>{
            handleClick(active)
        }, 100);
        // 添加窗口大小变化的事件监听器
        window.addEventListener('resize', fn);
        return ()=> {
            window.removeEventListener('resize', fn)
        }
    }, [active])
    useEffect(() => {
        // handleLoadedMetadata()
        // 移动端使用
        const srcollElements = document.querySelector('.m-location');
        const fn = () => {
            addStyleOnScroll()
        }
        if (srcollElements) {
            srcollElements.addEventListener('scroll', fn)
        };
        return ()=> {
            if (srcollElements) {
                srcollElements.removeEventListener('scroll', fn)
            }
        }
    }, [ isDesktopOrLaptop ]);
    const isElementInHorizontalViewport = (el) => {
        const rect = el.getBoundingClientRect();
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        return (
            rect.left >= 0 && rect.left <= 100
        );
    }
    const addStyleOnScroll = () => {
        const targetElements = document.querySelectorAll('.m-location-img');
        targetElements.forEach((el) => {
            if (isElementInHorizontalViewport(el)) {
                document.querySelector('.m-location-img.active').classList.remove('active')
                el.classList.add('active');
                setActive(+el.dataset.index)
            }
        });
    }
    const jobsList = [
        {
            "id": "1",
            "name": t("jobs.job_type_1")
        },
        {
            "id": "2",
            "name": t("jobs.job_type_2")
        },
        {
            "id": "3",
            "name": t("jobs.job_type_3")
        }
    ]
    
    useEffect(() => {
        let wows = new WOW.WOW({
            boxClass: 'wow',            // 默认的WOW容器类名
            animateClass: 'animated',   // 默认的CSS动画类名
            offset: 0,                  // 触发动画的元素距离可视窗口底部的偏移量
            mobile: true,               // 在移动设备上是否启用动画
            live: true,                 // 异步加载内容时是否重新初始化WOW
            scrollContainer: null
        });
        wows.init({
            delay: '0.3s',              // 触发动画前的延时
            duration: '2s',           // 动画持续时间
        });
    }, []);
    useEffect(() => {
        getJobsList();
        form.resetFields();
    }, [sharedData['lang']])
    // 获取岗位信息
    const getJobsList = async (data) => {
        setLoading(true)
        let params = { language: sharedData['lang'] };
        if (data) {
            params = {
                ...params,
                ...data
            }
        };
        try {
            const { list, cityList, positionList } = await GET_JOBS_LIST(params);
            setJobsInfo({ list, cityList, positionList })
            setVisible(-1)
            setVisibleMobile(-1)
        } finally {
            setLoading(false)
        }
    }

    const handleClick = (index) => {
        setActive(index);
        if (!isDesktopOrLaptop) return;
        const dom = document.querySelector('.location-item'),
        box = document.querySelector("#location-box");
        if (!dom || !box) {
            return
        }
        const dw = dom.clientWidth,bw=box.clientWidth;
        const step = (bw*0.04)/2;
        let obj = ''
        let obj0 =
        {
            gz: {
                style: `${dw+step}px, 0px`,
                class: 'active'
            },
            bj: {
                style: `${dw+step}px, 100px`,
                class: ''
            },
            sz: {
                style: `-${dw*2+step*2}px, 100px`,
                class: ''
            }
        }
        let obj1 =
        {
            gz: {
                style: `${dw*2+step*2}px, 100px`,
                class: ''
            },
            bj: {
                style: `-${dw+step}px, 100px`,
                class: ''
            },
            sz: {
                style: `-${dw+step}px, 0px`,
                class: 'active'
            }
        }
        let obj2 =
        {
            gz: {
                style: '0px, 100px',
                class: ''
            },
            bj: {
                style: '0px, 0px',
                class: 'active'
            },
            sz: {
                style: '0px, 100px',
                class: ''
            }
        }

        if (index == 0) {
            obj = obj0
        } else if (index == 2) {
            obj = obj1
        } else if (index == 1) {
            obj = obj2
        }
        setOffsetX(obj)
    }
    //设置下拉框DOM
    const getOptions = (optionItem) => {
        if (optionItem && optionItem.length != 0) {
            return optionItem.map((item, index) => (
                <Option value={item.id} key={index} label={item.name}>
                    {item.name}
                </Option>
            ))
        } else {
            return null
        }
    }
    
    const onFormLayoutChange = (filds, params) => {
        getJobsList(clearObj(params))
    }
    const handleTigger = (index) => {
        if (visible === index) {
            return
        }
        setVisible(index)
        let content = document.querySelectorAll('.recruit-item-con');
        if (content) {
            let scrollHeight = content[index].scrollHeight
            setVisibleHeight(`${scrollHeight}px`)
        }
        setTimeout(() => {
            const rect = refs.current.getBoundingClientRect();
            scroll.scrollTo(document.documentElement.scrollTop+(rect.top - 150 + (index*88)),{
                duration: 300,
                smooth: true,
            })
        }, 400);
    }
    const handleClose = (e) => {
        setVisible(-1)
        e.preventDefault()
        e.stopPropagation()
    }

    const handleTiggerMobile = (index) => {
        if (visibleMobile === index) {
            return
        }
        setVisibleMobile(index)
        let content = document.querySelectorAll('.m-recruit-item-con');
        if (content) {
            let scrollHeight = content[index].scrollHeight
            console.log('scrollHeight', scrollHeight)
            setVisibleHeightMobile(`${scrollHeight}px`)
        }
        setTimeout(() => {
            const rect = refs.current.getBoundingClientRect();
            scroll.scrollTo(document.documentElement.scrollTop+(rect.top - 150 + (index*88)), {
                duration: 300,
                smooth: true,
            })
        }, 400);
    }
    const handleCloseMobile = (e) => {
        console.log('top', e)
        setVisibleMobile(-1)
        e.preventDefault()
        e.stopPropagation()
    }

    return (
        <div>
           
            {
                isDesktopOrLaptop && (
                    <div>
                        <div className='jobs-wrap-banner'>
                            <div className='container'>
                                <h2><Texty>JOIN US</Texty></h2>
                                <p key={sharedData['lang']}><Texty type="bottom">{t('jobs.banner_title')}</Texty></p>
                            </div>
                        </div>
                        <div className="environment" id='w-bghj'>
                            <div className='homepage-product-header container'>
                                <h3 className='home-h3-title wow fadeInUp'>
                                    {t('jobs.jobs_local_title')}
                                </h3>
                            </div>
                            <div className="environment-null"></div>
                            <div className='bg-gray wow fadeInUp'>
                                <div className='container'>
                                    <div className='location' id='location-box'>
                                        <div
                                            onClick={() => handleClick(0)}
                                            className={`location-item left ${offsetX['gz']['class']}`}
                                            style={{ transform: `translate(${offsetX['gz']['style']})` }}
                                        >
                                            <img className='location-img' src={imageJobPaths['guangzhou']} alt='pic'></img>
                                            <h4 className='location-name'>{t('jobs.local_gz')}</h4>
                                            <div className='image-overlay'></div>
                                        </div>

                                        <div
                                            onClick={() => handleClick(1)}
                                            className={`location-item center ${offsetX['bj']['class']}`}
                                            style={{ transform: `translate(${offsetX['bj']['style']})` }}>
                                            <img className='location-img' src={imageJobPaths['beijing']} alt='pic'></img>
                                            <h4 className='location-name'>{t('jobs.local_bj')}</h4>
                                            <div className='image-overlay'></div>
                                        </div>

                                        <div
                                            onClick={() => handleClick(2)}
                                            className={`location-item right ${offsetX['sz']['class']}`}
                                            style={{ transform: `translate(${offsetX['sz']['style']})` }}
                                        >
                                            <img className='location-img' src={imageJobPaths['shenzhen']} alt='pic'></img>
                                            <h4 className='location-name'>{t('jobs.local_sz')}</h4>
                                            <div className='image-overlay'></div>
                                        </div>

                                    </div>
                                    <div className='corporation-environmen wow fadeInUp'>
                                        <Image.PreviewGroup>
                                            {
                                             imageJobPaths[active].map((url, index)=> {
                                                return (
                                                    <Image key={index} src={ url }
                                                    />
                                                )
                                             })   
                                            }
                                        </Image.PreviewGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='jobs-fl' id='w-ygfl'>
                            <div className='homepage-product-header container'>
                                <h3 className='home-h3-title wow fadeInUp'>
                                    {t('footer.jobs_sub_employe')}
                                </h3>
                            </div>
                            <div className='container'>
                                <div className='fl-list wow fadeInUp'>
                                    <div className='fl-list-item'>
                                        <img src={imageJobPaths['flItem1']} alt="pic" />
                                        <p>{t('jobs.fl_item_1')}</p>
                                    </div>
                                    <div className='fl-list-item'>
                                        <img src={imageJobPaths['flItem2']} alt="pic" />
                                        <p>{t('jobs.fl_item_2')}</p>
                                    </div>
                                    <div className='fl-list-item'>
                                        <img src={imageJobPaths['flItem3']} alt="pic" />
                                        <p>{t('jobs.fl_item_3')}</p>
                                    </div>
                                    <div className='fl-list-item'>
                                        <img src={imageJobPaths['flItem4']} alt="pic" />
                                        <p>{t('jobs.fl_item_4')}</p>
                                    </div>
                                    <div className='fl-list-item'>
                                        <img src={imageJobPaths['flItem5']} alt="pic" />
                                        <p>{t('jobs.fl_item_5')}</p>
                                    </div>
                                    <div className='fl-list-item'>
                                        <img src={imageJobPaths['flItem6']} alt="pic" />
                                        <p>{t('jobs.fl_item_6')}</p>
                                    </div>
                                    <div className='fl-list-item'>
                                        <img src={imageJobPaths['flItem7']} alt="pic" />
                                        <p>{t('jobs.fl_item_7')}</p>
                                    </div>
                                    <div className='fl-list-item'>
                                        <img src={imageJobPaths['flItem8']} alt="pic" />
                                        <p>{t('jobs.fl_item_8')}</p>
                                    </div>
                                    <div className='fl-list-item'>
                                        <img src={imageJobPaths['flItem9']} alt="pic" />
                                        <p>{t('jobs.fl_item_9')}</p>
                                    </div>
                                    <div className='fl-list-item'>
                                        <img src={imageJobPaths['flItem10']} alt="pic" />
                                        <p>{t('jobs.fl_item_10')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-gray jobs-info' id='w-zpxx'>
                            <div className='ab_left'></div>
                            <div className='ab_right'></div>
                            <div className='homepage-product-header container'>
                                <h3 className='home-h3-title wow fadeInUp'>
                                    {t('jobs.jobs_sub_recruitment')}
                                </h3>
                            </div>
                            <div className='container wow fadeInUp'>
                                <Form
                                    layout="inline"
                                    form={form}
                                    // initialValues={{ layout: formLayout }}
                                    onValuesChange={onFormLayoutChange}
                                    size='large'
                                    className='jobs-form'
                                >
                                    <Form.Item label="" name="city">
                                        <Select
                                            placeholder={t('jobs.jobs_placeholder_local')}
                                            bordered={false}
                                            style={{ padding: '14px 0' }}
                                            popupClassName="popup"
                                            allowClear
                                            listItemHeight={50}
                                            listHeight={250}
                                        >
                                            {getOptions(jobsInfo.cityList)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label="" name="category_id">
                                        <Select
                                            placeholder={t('jobs.jobs_placeholder_recruitment')}
                                            bordered={false}
                                            style={{ padding: '14px 0' }}
                                            popupClassName="popup"
                                            allowClear
                                            listItemHeight={50}
                                            listHeight={250}
                                        >
                                            {getOptions(jobsInfo.positionList)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label="" name="position_type_id">
                                        <Select
                                            placeholder={t('jobs.jobs_placeholder_type')}
                                            bordered={false}
                                            style={{ padding: '14px 0' }}
                                            popupClassName="popup"
                                            allowClear
                                        >
                                            {getOptions(jobsList)}
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div className='recruit container wow fadeInUp'>
                                {/* <div className={`recruit-item ${visible == 0 ? 'active' : ''}`} onClick={() => handleTigger(0)}>
                                    <div className='recruit-item-title'>
                                        <span>
                                            平面设计师
                                        </span>
                                        <span>
                                            创意设计部
                                        </span>
                                        <span>
                                            全职
                                        </span>
                                        <span>
                                            更新：2024/01/02
                                        </span>
                                        <div className='recruit-close' onClick={(e) => handleClose(e)}>
                                            {InlineBi}
                                        </div>
                                    </div>
                                    <div className='recruit-item-con'
                                        style={{ maxHeight: `${visible == 0 ? visibleHeight : ''}` }}
                                    >
                                        <p className='local-tips'>广州 | 经验不限 | 博士</p>
                                        <h5>
                                            职位描述
                                        </h5>
                                        <p>
                                            1.根据要求制作游戏广告视频 (包括创意、剪辑、合成、特效、音效等内容)，按时按质按量独立完成视频制作<br />
                                            2.熟练使用After Effects、Premiere、Photoshop剪映等软件工具<br />
                                            3.具备良好的视频广告创作能力，能把握好素材广告的卖点，当下的热点等方面进行创作<br />
                                            4.熟悉各类视频网站，有挖掘网络视频素材能力<br />
                                        </p>
                                        <h5>
                                            职位要求
                                        </h5>
                                        <p>
                                            1.根据要求制作游戏广告视频 (包括创意、剪辑、合成、特效、音效等内容)，按时按质按量独立完成视频制作<br />
                                        </p>
                                        <h5>
                                            简历投递
                                        </h5>
                                        <p>
                                            hr-admin@gm825.com
                                        </p>
                                    </div>
                                </div> */}
                                <Spin spinning={loading}  >
                                    <div ref={refs}></div>
                                    {jobsInfo.list.map((item, index) => {
                                        return (
                                            <div key={item.id} className={`recruit-item ${visible == index ? 'active' : ''}`}   onClick={(e) =>  handleTigger(index)}>
                                                <div className='recruit-item-title' id={'item-'+index} onClick={(e) => visible == index&&handleClose(e)}>
                                                    <span>
                                                        {item.name}
                                                    </span>
                                                    <span>
                                                        {item.category_id}
                                                    </span>
                                                    <span>
                                                        {item.position_type}
                                                    </span>
                                                    <span className='right'>
                                                        {t('jobs.job_other')}：{item.mtime || '无'}
                                                    </span>
                                                    <div className='recruit-close' onClick={(e) => handleClose(e)}>
                                                        {InlineBi}
                                                    </div>
                                                </div>
                                                <div className='recruit-item-con'
                                                    style={{ maxHeight: `${visible == index ? visibleHeight : ''}` }}
                                                >
                                                    <p className='local-tips'>{item.city} | {item.experience || '经验不限'} | {item.education || '学历不限'}</p>
                                                    <h5>
                                                        {t('jobs.jobs_desc')}
                                                    </h5>
                                                    <p dangerouslySetInnerHTML={{ __html: item.position_responsibility }} />
                                                    <h5>
                                                        {t('jobs.jobs_ask')}
                                                    </h5>
                                                    <p dangerouslySetInnerHTML={{ __html: item.position_requirements }} />
                                                    <h5>
                                                        {t('jobs.jobs_type')}
                                                    </h5>
                                                    <p>
                                                        {item.hiring_manager}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {!jobsInfo.list.length && <Empty description={t('jobs.job_empty')} />}
                                </Spin>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                !isDesktopOrLaptop && (
                    <div>
                        <div className='m-jobs-wrap-banner'>
                            <div className='container'>
                                <h2>JOIN US</h2>
                                <p>{t('jobs.banner_title')}</p>
                            </div>
                        </div>
                        <div className="m-environment bg-gray" id='w-bghj'>
                            <div>
                                <div className='m-homepage-product-header container wow fadeInUp'>
                                    <h3 className='m-home-h3-title'>
                                        {t('jobs.jobs_local_title')}
                                    </h3>
                                </div>
                                <div className='m-overflow'>
                                    <div className='m-location wow fadeInUp'>
                                       

                                        <div
                                            className='m-location-img active'
                                            onClick={() => handleClick(1)}
                                            data-index={1}
                                        >
                                            <img src={imageJobPaths['beijing']} alt='pic'></img>
                                            <h4 className='m-location-name'>{t('jobs.local_bj')}</h4>
                                        </div>

                                        <div
                                            onClick={() => handleClick(2)}
                                            data-index={2}
                                            className='m-location-img'
                                        >
                                            <img src={imageJobPaths['shenzhen']} alt='pic'></img>
                                            <h4 className='m-location-name'>{t('jobs.local_sz')}</h4>
                                        </div>
                                        <div
                                           onClick={() => handleClick(0)}
                                           className='m-location-img'
                                           data-index={0}
                                        >
                                            <img src={imageJobPaths['guangzhou']} alt='pic'></img>
                                            <h4 className='m-location-name'>{t('jobs.local_gz')}</h4>
                                        </div>
                                    </div>
                                    <div className='m-corporation-environmen wow fadeInUp'>
                                        <Image.PreviewGroup>
                                        {
                                             imageJobPaths[active].map((url, index)=> {
                                                return (
                                                    <Image height={165} key={index} src={ url }
                                                    />
                                                )
                                             })   
                                            }
                                            {/* <Image  src={imageJobPaths['environmenItem1']}

                                                preview={{ scaleStep: 100 }}
                                            /> */}
                                        </Image.PreviewGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='m-jobs-fl' id='w-ygfl'>
                            <div className='m-homepage-product-header container'>
                                <h3 className='m-home-h3-title wow fadeInUp'>
                                    {t('footer.jobs_sub_employe')}
                                </h3>
                            </div>
                            <div className='container'>
                                <div className='m-fl-list wow fadeInUp'>
                                    <div className='m-fl-list-item'>
                                        <img src={imageJobPaths['flItem1']} alt="pic" />
                                        <p>{t('jobs.fl_item_1')}</p>
                                    </div>
                                    <div className='m-fl-list-item'>
                                        <img src={imageJobPaths['flItem2']} alt="pic" />
                                        <p>{t('jobs.fl_item_2')}</p>
                                    </div>
                                    <div className='m-fl-list-item'>
                                        <img src={imageJobPaths['flItem3']} alt="pic" />
                                        <p>{t('jobs.fl_item_3')}</p>
                                    </div>
                                    <div className='m-fl-list-item'>
                                        <img src={imageJobPaths['flItem4']} alt="pic" />
                                        <p>{t('jobs.fl_item_4')}</p>
                                    </div>
                                    <div className='m-fl-list-item'>
                                        <img src={imageJobPaths['flItem5']} alt="pic" />
                                        <p>{t('jobs.fl_item_5')}</p>
                                    </div>
                                    <div className='m-fl-list-item'>
                                        <img src={imageJobPaths['flItem6']} alt="pic" />
                                        <p>{t('jobs.fl_item_6')}</p>
                                    </div>
                                    <div className='m-fl-list-item'>
                                        <img src={imageJobPaths['flItem7']} alt="pic" />
                                        <p>{t('jobs.fl_item_7')}</p>
                                    </div>
                                    <div className='m-fl-list-item'>
                                        <img src={imageJobPaths['flItem8']} alt="pic" />
                                        <p>{t('jobs.fl_item_8')}</p>
                                    </div>
                                    <div className='m-fl-list-item'>
                                        <img src={imageJobPaths['flItem9']} alt="pic" />
                                        <p>{t('jobs.fl_item_9')}</p>
                                    </div>
                                    <div className='m-fl-list-item'>
                                        <img src={imageJobPaths['flItem10']} alt="pic" />
                                        <p>{t('jobs.fl_item_10')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='bg-gray m-jobs-info wow fadeInUp' id='w-zpxx'>
                            <div className='m-ab_left'></div>
                            <div className='m-ab_right'></div>
                            <div className='m-homepage-product-header container'>
                                <h3 className='m-home-h3-title'>
                                    {t('jobs.jobs_sub_recruitment')}
                                </h3>
                            </div>
                            <div className='container'>
                                <Form
                                    layout="inline"
                                    form={form}
                                    // initialValues={{ layout: formLayout }}
                                    onValuesChange={onFormLayoutChange}
                                    size='small'
                                    className='m-jobs-form'
                                >
                                    <Form.Item label="" name="city">
                                        <Select
                                            placeholder={t('jobs.jobs_placeholder_local')}
                                            bordered={false}
                                            style={{ padding: '14px 0' }}
                                            popupClassName="popup"
                                            allowClear
                                            listItemHeight={50}
                                            listHeight={250}
                                        >
                                            {getOptions(jobsInfo.cityList)}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item label="" name="category_id">
                                        <Select
                                            placeholder={t('jobs.jobs_placeholder_recruitment')}
                                            bordered={false}
                                            style={{ padding: '14px 0' }}
                                            popupClassName="popup"
                                            allowClear
                                            listItemHeight={50}
                                            listHeight={250}
                                        >
                                            {getOptions(jobsInfo.positionList)}
                                        </Select>
                                    </Form.Item>
                                    <br></br>
                                    <Form.Item label="" name="position_type_id">
                                        <Select
                                            placeholder={t('jobs.jobs_placeholder_type')}
                                            bordered={false}
                                            style={{ padding: '14px 0' }}
                                            popupClassName="popup"
                                            allowClear
                                        >
                                            {getOptions(jobsList)}
                                        </Select>
                                    </Form.Item>
                                </Form>
                            </div>
                            <div className='m-recruit container'>
                                {/* <div className={`m-recruit-item ${visibleMobile == 0 ? 'active' : ''}`} onClick={() => handleTiggerMobile(0)}>
                                    <div className='m-recruit-item-title'>
                                        <span>
                                            平面设计师
                                        </span>
                                        <span>
                                            创意设计部
                                        </span>
                                        <span>
                                            全职
                                        </span>
                                        <div className='m-recruit-close' onClick={(e) => handleCloseMobile(e)}>
                                            {InlineBi}
                                        </div>
                                    </div>
                                    <div className='m-recruit-item-con'
                                        style={{ maxHeight: `${visibleMobile == 0 ? visibleHeightMobile : ''}` }}
                                    >
                                        <p className='local-tips'>广州 | 经验不限 | 博士</p>
                                        <h5>
                                            职位描述
                                        </h5>
                                        <p>
                                            1.根据要求制作游戏广告视频 (包括创意、剪辑、合成、特效、音效等内容)，按时按质按量独立完成视频制作<br />
                                            2.熟练使用After Effects、Premiere、Photoshop剪映等软件工具<br />
                                            3.具备良好的视频广告创作能力，能把握好素材广告的卖点，当下的热点等方面进行创作<br />
                                            4.熟悉各类视频网站，有挖掘网络视频素材能力<br />
                                        </p>
                                        <h5>
                                            职位要求
                                        </h5>
                                        <p>
                                            1.根据要求制作游戏广告视频 (包括创意、剪辑、合成、特效、音效等内容)，按时按质按量独立完成视频制作<br />
                                        </p>
                                        <h5>
                                            简历投递
                                        </h5>
                                        <p>
                                            hr-admin@gm825.com
                                        </p>
                                    </div>
                                </div> */}
                                <Spin spinning={loading} >
                                    <div ref={refs}></div>
                                    {jobsInfo.list.map((item, index) => {
                                        return (
                                            <div key={item.id} className={`m-recruit-item ${visibleMobile == index ? 'active' : ''}`} onClick={() => handleTiggerMobile(index)}>
                                                <div className='m-recruit-item-title' onClick={(e) => visibleMobile === index&& handleCloseMobile(e)}>
                                                    <span>
                                                        {item.name}
                                                    </span>
                                                    <span>
                                                        {item.category_id}
                                                    </span>
                                                    <span>
                                                        {item.position_type}
                                                    </span>
                                                    <div className='m-recruit-close' onClick={(e) => handleCloseMobile(e)}>
                                                        {InlineBi}
                                                    </div>
                                                </div>
                                                <div className='m-recruit-item-con'
                                                    style={{ maxHeight: `${visibleMobile == index ? visibleHeightMobile : ''}` }}
                                                >
                                                    <p className='local-tips'>{item.city} | {item.experience || '经验不限'} | {item.education || '学历不限'}</p>
                                                    <h5>
                                                        {t('jobs.jobs_desc')}
                                                    </h5>
                                                    <p dangerouslySetInnerHTML={{ __html: item.position_responsibility }} />
                                                    <h5>
                                                        {t('jobs.jobs_ask')}
                                                    </h5>
                                                    <p dangerouslySetInnerHTML={{ __html: item.position_requirements }} />
                                                    <h5>
                                                        {t('jobs.jobs_type')}
                                                    </h5>
                                                    <p>
                                                        {item.hiring_manager}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {!jobsInfo.list.length && <Empty description={t('jobs.job_empty')} />}
                                </Spin>
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
export default Jobs