import React, { useState, useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { throttle } from 'lodash'
import { useMediaQuery } from 'react-responsive'
import { useMyContext } from '../../layouts/index';
import {  animateScroll as scroll } from "react-scroll";
import { Parallax } from 'rc-scroll-anim';
import WOW from 'wowjs';
import Texty from 'rc-texty';
import MoreInfo from './moreInfo'
import MoreInfoNewsKpi from './moreInfoNewsKpi'
import MoreInfoNewsOther from './moreInfoNewsOther'
import MoreInfoNotice from './moreInfoNotice'
import MoreInfoTrends from './moreInfoTrends.jsx'
import { GET_INV_PROSPECTUS, GET_INV_FIN_NOTICE } from '../../services/data.js'
import { formatDate, downloadFile } from '../../utils/index.js'
import { useTranslation, Trans } from 'react-i18next';
import animType from 'rc-texty/lib/animTypes';
import 'rc-texty/assets/index.css';

const arrowRight = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-arrow-right" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z" />
</svg>
const Investor = () => {
    const sharedData = useMyContext(); //共享语言切换
    const [indicatorWidth, setIndicatorWidth] = useState(0)
    const [indicatorOffset, setIndicatorOffset] = useState(0)
    const [active, setActive] = useState(0);
    const [prospectusList, setProspectusList] = useState([]);//招股文件
    const [noticeList, setNoticeList] = useState([]);//财务公告模块
    const { t, i18n } = useTranslation();
    const successNum = useRef(0)
    let location = useLocation();
    const setSuccessNum = ()=> {
        successNum.current +=1;
        if (successNum.current === 6) {
            setTimeout(() => {
                if (!location.hash) {
                    return
                }
                const dom = document.querySelector(location.hash);
                if (dom) {
                    scroll.scrollTo(dom.offsetTop - 80)
                }
            }, 0);
        }
    }
    // 小于768 切换移动端
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    })
    useEffect(() => {
        updateDimensions()
        // 添加窗口大小变化的事件监听器
        window.addEventListener('resize', throttle(updateDimensions, 100));
    });
    useEffect(() => {
        let wows = new WOW.WOW({
            boxClass: 'wow',            // 默认的WOW容器类名
            animateClass: 'animated',   // 默认的CSS动画类名
            offset: 0,                  // 触发动画的元素距离可视窗口底部的偏移量
            mobile: true,               // 在移动设备上是否启用动画
            live: true,                 // 异步加载内容时是否重新初始化WOW
            scrollContainer: null
        });
        wows.init({
            delay: '0.3s',              // 触发动画前的延时
            duration: '2s',           // 动画持续时间
        });
    }, []);
    useEffect(() => {
        getList()
    },[sharedData['lang']])
    const getList = async ()=> {
        const params = {language:sharedData['lang']}
        GET_INV_PROSPECTUS(params).then(({list})=> {
            setProspectusList(list.list);
            setSuccessNum()
        });

        GET_INV_FIN_NOTICE(params).then(({ list }) => {
            setNoticeList(list.list);
            setSuccessNum()
        });
    }
    const updateDimensions = () => {
        // const selectedTab = document.querySelectorAll('.m-tabs-item')[0];
        let selectedTab = ''
        if (isDesktopOrLaptop) {
            selectedTab = document.querySelectorAll('.tabs-item')[0];
        } else {
            selectedTab = document.querySelectorAll('.m-tabs-item')[0];
        }
        if (!selectedTab) return;
        let offMove = active * selectedTab.offsetWidth
        setIndicatorWidth(selectedTab.offsetWidth)
        setIndicatorOffset(offMove)
    }
    // 切换
    const handleTagActive = (index) => {
        setActive(index)
        // const selectedTab = document.querySelectorAll('.m-tabs-item')[index];
        let selectedTab = ''
        if (isDesktopOrLaptop) {
            selectedTab = document.querySelectorAll('.tabs-item')[0];
        } else {
            selectedTab = document.querySelectorAll('.m-tabs-item')[0];
        }
        if (!selectedTab) return;
        let offMove = index * selectedTab.offsetWidth
        setIndicatorOffset(offMove)
    }

    const handleWaiHerf = () => {
        window.open('https://www1.hkexnews.hk/search/titlesearch.xhtml?lang=zh')
    }
    return (
        <div>
            {
                isDesktopOrLaptop && (
                    <div>
                        <div className='investor-wrap-banner' id='w-ljs' onClick={handleWaiHerf} style={{ cursor: 'pointer' }}>
                            <div className='container'>
                                <h2>{t("investor.inv_together_place")}  {arrowRight}</h2>
                            </div>
                        </div>
                        <div id='w-qygz'>
                            <div className='container'>
                                    <div className='governance wow fadeInUp'>
                                        <div className='logo'></div>
                                        <div className='governance-con'>
                                            <h4>{t("investor.inv_control")}</h4>
                                            <p>
                                                {t("investor.inv_control_info_1")}
                                            </p>
                                            <p>
                                                {t("investor.inv_control_info_2")}
                                            </p>
                                        </div>
                                    </div>
                            </div>
                        </div>


                        <div className='bg-pic-1' id='w-gszc'>
                                <div className='container wow fadeInUp'>
                                    <div className='investor-product-header container'>
                                        <h3 className='home-h3-title' style={{ color: '#ffffff' }}>
                                            {t("investor.inv_rules")}
                                        </h3>
                                    </div>
                                    <div className='more'>
                                        <MoreInfo update={ setSuccessNum } />
                                    </div>
                                </div>
                        </div>


                        <div className='bg-pic-2' id='w-cwxwg'>
                                <div className='container wow fadeInUp'>
                                    <div className='investor-product-header container'>
                                        <h3 className='home-h3-title'>
                                            {t("investor.inv_news")}
                                        </h3>
                                    </div>
                                    <div className='news-tabs'>
                                        <div className='news-tabs-flex'>
                                            <div className={`tabs-item ${active == 0 ? 'active' : ''}`} onClick={() => handleTagActive(0)}>
                                                {t("investor.inv_news_kpi")}
                                            </div>
                                            <div className={`tabs-item ${active == 1 ? 'active' : ''}`} onClick={() => handleTagActive(1)}>
                                                {t("investor.inv_news_other")}
                                            </div>
                                        </div>
                                        <div
                                            className='active-border'
                                            style={{ width: indicatorWidth + 'px', transform: 'translateX(' + indicatorOffset + 'px)' }}
                                        ></div>
                                    </div>
                                    <div className='news-tabs-connnet'>
                                        {active === 0 ? <MoreInfoNewsKpi update={ setSuccessNum } /> : <MoreInfoNewsOther />}
                                    </div>
                                </div>
                        </div>


                        <div className='bg-pic-3' id='w-gsgg'>
                                <div className='container wow fadeInUp'>
                                    <div className='investor-product-header container'>
                                        <h3 className='home-h3-title'>
                                            {t("investor.inv_notice")}
                                        </h3>
                                    </div>
                                    <div className='more'>
                                        <MoreInfoNotice update={ setSuccessNum } />
                                    </div>
                                </div>
                        </div>


                        <div className='file' id='w-zgwj'>
                                <div className='container wow fadeInUp'>
                                    <div className='investor-product-header container'>
                                        <h3 className='home-h3-title'>
                                            {t("investor.inv_files")}
                                        </h3>
                                    </div>
                                    <div className='file-list'>
                                        {
                                            prospectusList.map((item) => {
                                                return (
                                                    <div key={item.id} className='file-item'>
                                                        <span>{formatDate(item.ctime)}</span>
                                                        <p>{item.title}</p>
                                                        <a href={item.url} download={item.title + '.pdf'}>{t("investor.inv_download")}</a>
                                                    </div>
                                                )
                                            })
                                        }
                                        {/* <div className='file-item'>
                                        <span>2018/12/11</span>
                                        <p>白色申请表</p>
                                        <a href="javascript:;">点击下载</a>
                                    </div> */}

                                    </div>
                                </div>
                        </div>


                        <div className='finance' id='w-cwbg'>
                            <div className='container'>
                                <div className='investor-product-header container'>
                                    <h3 className='home-h3-title wow fadeInUp'>
                                            {t("investor.inv_finance")}
                                    </h3>
                                </div>
                                    <div className='finance-list wow fadeInUp'>
                                        {
                                            noticeList.map(item => {
                                                return (
                                                    <a href={item.url} target="_blank" key={item.id} className='finance-item'>
                                                        <img src={item.img} alt="pic" />
                                                        <p>
                                                            {item.title}
                                                        </p>
                                                    </a>
                                                )
                                            })
                                        }
                                        {/* <div className='finance-item'>
                                        <img src={financeImg1} alt="pic" />
                                        <p>白色申请表</p>
                                    </div> */}
                                    </div>
                            </div>
                        </div>

                        <div className='bg-pic-4' id='w-tzzgxdt'>
                            <div className='container wow fadeInUp'>
                                <div className='investor-product-header container'>
                                    <h3 className='home-h3-title'>
                                        {t("investor.inv_trends")}
                                    </h3>
                                </div>
                                <div className='more'>
                                    <MoreInfoTrends update={ setSuccessNum } />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {!isDesktopOrLaptop && (
                <div>
                    <div className='m-investor-wrap-banner' id='w-ljs' onClick={handleWaiHerf}>
                        <div className='container'>
                            <h2>{t("investor.inv_together_place")}  {arrowRight}</h2>
                        </div>
                    </div>
                    <div id='w-qygz'>
                        <div className='container'>
                            <div className='m-governance'>
                                {/* <div className='logo'></div> */}
                                <div className='governance-con wow fadeInUp'>
                                    <h4>{t("investor.inv_control")}</h4>
                                    <p>
                                        {t("investor.inv_control_info_1")}
                                    </p>
                                    <p>
                                        {t("investor.inv_control_info_2")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='bg-pic-1' id='w-gszc'>
                        <div className='container wow fadeInUp'>
                            <div className='m-investor-product-header container'>
                                <h3 className='m-home-h3-title' style={{ color: '#ffffff' }}>
                                    {t("investor.inv_rules")}
                                </h3>
                            </div>
                            <div className='more'>
                                <MoreInfo update={ setSuccessNum } />
                            </div>
                        </div>
                    </div>

                    <div className='bg-pic-2' id='w-cwxwg'>
                        <div className='container wow fadeInUp'>
                            <div className='m-investor-product-header container'>
                                <h3 className='m-home-h3-title'>
                                    {t("investor.inv_news")}
                                </h3>
                            </div>
                            <div className='m-news-tabs'>
                                <div className='m-news-tabs-flex'>
                                    <div className={`m-tabs-item ${active == 0 ? 'active' : ''}`} onClick={() => handleTagActive(0)}>
                                        {t("investor.inv_news_kpi")}
                                    </div>
                                    <div className={`m-tabs-item ${active == 1 ? 'active' : ''}`} onClick={() => handleTagActive(1)}>
                                        {t("investor.inv_news_other")}
                                    </div>
                                </div>
                                <div
                                    className='m-active-border'
                                    style={{ width: indicatorWidth + 'px', transform: 'translateX(' + indicatorOffset + 'px)' }}
                                ></div>
                            </div>
                            <div className='m-news-tabs-connnet'>
                                {active === 0 ? <MoreInfoNewsKpi update={ setSuccessNum } /> : <MoreInfoNewsOther />}
                            </div>
                        </div>
                    </div>

                    <div className='bg-pic-3' id='w-gsgg'>
                        <div className='container wow fadeInUp'>
                            <div className='m-investor-product-header container'>
                                <h3 className='m-home-h3-title'>
                                    {t("investor.inv_notice")}
                                </h3>
                            </div>
                            <div className='more'>
                                <MoreInfoNotice update={ setSuccessNum } />
                            </div>
                        </div>
                    </div>

                    <div className='m-file' id='w-zgwj'>
                        <div className='container wow fadeInUp'>
                            <div className='m-investor-product-header container'>
                                <h3 className='m-home-h3-title'>
                                    {t("investor.inv_files")}
                                </h3>
                            </div>
                            <div className='m-overflow'>
                                <div className='file-list'>
                                    {
                                        prospectusList.map((item) => {
                                            return (
                                                <div key={item.id} className='file-item'>
                                                    <span>{formatDate(item.ctime)}</span>
                                                    <p>{item.title}</p>
                                                    <a href={item.url} download={item.title + '.pdf'}>{t("investor.inv_download")}</a>
                                                </div>
                                            )
                                        })
                                    }
                                    {/* <div className='file-item'>
                                        <span>2018/12/11</span>
                                        <p>白色申请表</p>
                                        <a href="javascript:;">点击下载</a>
                                    </div> */}
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='m-finance' id='w-cwbg'>
                        <div className='container wow fadeInUp'>
                            <div className='m-investor-product-header container'>
                                <h3 className='m-home-h3-title'>
                                    {t("investor.inv_finance")}
                                </h3>
                            </div>
                            <div className='m-finance-list'>
                                {
                                    noticeList.map(item => {
                                        return (
                                            <a href={item.url} target="_blank" key={item.id} className='m-finance-item'>
                                                <img src={item.img} alt="pic" />
                                                <p>
                                                    {item.title}
                                                </p>
                                            </a>
                                        )
                                    })
                                }
                                {/* <div className='m-finance-item'>
                                    <img src={financeImg1} alt="pic" />
                                    <p>白色申请表</p>
                                </div> */}

                            </div>
                        </div>
                    </div>

                    <div className='bg-pic-4' id='w-tzzgxdt'>
                        <div className='container wow fadeInUp'>
                            <div className='m-investor-product-header container'>
                                <h3 className='m-home-h3-title'>
                                    {t("investor.inv_trends")}
                                </h3>
                            </div>
                            <div className='more'>
                                <MoreInfoTrends update={ setSuccessNum } />
                            </div>
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    )
}
export default Investor