import UserLayouts from '../layouts';
import Home from '../pages/Home';
import Business from '../pages/Business';
import News from '../pages/News';
import Info from '../pages/Info';
import Investor from '../pages/Investor';
import Jobs from '../pages/Jobs';


const routes = [
    {
        path: '*',
        id: '404',
        component: Error,
        meta: {
            title: '404'
        }
    },
    {
        path: '/',
        id: '1',
        component: UserLayouts,
        meta: {
            title: 'webkite'
        },
        children: [
            {
                path: '',
                meta: {
                    title: '首页',
                    icon: 'home',
                },
                id: '1-1',
                component: Home,
            },
            {
                path: '/business',
                meta: {
                    title: '核心业务',
                    icon: 'business',
                },
                id: '1-2',
                component: Business,
            },
            {
                path: '/news',
                meta: {
                    title: '新闻中心',
                    icon: 'News',
                },
                id: '1-3',
                component: News,
            },
            {
                path: '/news/info',
                meta: {
                    title: '新闻详情',
                    icon: 'News',
                },
                id: '1-3-1',
                component: Info,
            },
            {
                path: '/investor',
                meta: {
                    title: '投资者关系',
                    icon: 'investor',
                },
                id: '1-4',
                component: Investor,
            },
            {
                path: '/jobs',
                meta: {
                    title: '加入我们',
                    icon: 'jobs',
                },
                id: '1-5',
                component: Jobs
            },
        ]
    }
]

export default routes;