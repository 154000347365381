import React, { useState, useEffect, useRef } from 'react'
import { Pagination, Carousel } from 'antd';
import { throttle } from 'lodash'
import Texty from 'rc-texty';
import WOW from 'wowjs';
import { useMediaQuery } from 'react-responsive'
import { useNavigate, useLocation } from 'react-router-dom'
import { animateScroll as scroll } from "react-scroll";
import { useTranslation, Trans } from 'react-i18next';
import { useMyContext } from '../../layouts/index';
import { GET_NEWS_LIST, GET_NEWS_CATEGORY } from '../../services/data.js'
import { getParamsUrl } from '../../utils'
import 'rc-texty/assets/index.css';
import { imageBusinessPaths, LogoImg } from '../Home/exportImg.js'
const Heading = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-bookmark-fill" viewBox="0 0 16 16">
    <path d="M2 2v13.5a.5.5 0 0 0 .74.439L8 13.069l5.26 2.87A.5.5 0 0 0 14 15.5V2a2 2 0 0 0-2-2H4a2 2 0 0 0-2 2z" />
</svg>

const News = ({scrollToTop}) => {
    const sharedData = useMyContext(); //共享语言切换
    const [indicatorWidth, setIndicatorWidth] = useState(0)
    const [indicatorOffset, setIndicatorOffset] = useState(0)
    const [active, setActive] = useState(0)
    const [list, setList] = useState([])
    const [pn, setPn] = useState(1)
    const [total, setTotal] = useState(10)
    const [bannerList, setBannerList] = useState([])
    const [categories, setCategories] = useState([])
    const [typeId, setTypeId] = useState(null)
    const navigate = useNavigate();
    let location = useLocation()
    const {search} = location
    const { t, i18n } = useTranslation();
    // 小于768 切换移动端
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    });
    sharedData.newsChangeCallback((id)=> {
        handleTagActive(id)
    })
    useEffect(() => {
        const fn = throttle(()=>{
            updateDimensions(active)
        }, 200) 
        updateDimensions(active)
        // 添加窗口大小变化的事件监听器
        window.addEventListener('resize', fn);
        return ()=> {
            window.removeEventListener('resize', fn)
        }
    }, [active])
    useEffect(() => {
        updateDimensions(active)
        const index = categories.findIndex(v=> {
            return v.id === typeId
        })
        setActive( index )
    }, [categories])
    useEffect(() => {
        let wows = new WOW.WOW({
            boxClass: 'wow',            // 默认的WOW容器类名
            animateClass: 'animated',   // 默认的CSS动画类名
            offset: 0,                  // 触发动画的元素距离可视窗口底部的偏移量
            mobile: true,               // 在移动设备上是否启用动画
            live: true,                 // 异步加载内容时是否重新初始化WOW
            scrollContainer: null,
            resetAnimation: true
        });
        wows.init({
            delay: '0.3s',              // 触发动画前的延时
            duration: '2s',           // 动画持续时间
        });
    }, []);
    useEffect(() => {
        typeId&&fetch_get_list(typeId, pn)
    }, [typeId])
    useEffect(() => {
        const { pn: pageNum, active: ac = active } = getParamsUrl();
        if (pageNum) {
            setPn(+pageNum)
        }
        getCategories( ac);
        navigate(`/news`)
    }, [sharedData['lang']])
    // 跳转详情页
    const goInfo = (id, type)=> {
        navigate(`/news/info?id=${id}&pn=${pn}&active=${active}`)
    };
    const getCategories = async (index)=> {
        const params = {
            language: sharedData['lang']
        }
        return await GET_NEWS_CATEGORY(params).then(res=> {
            const list = res.list.categories;
            setTypeId(list[index === -1? 0 : index].id)
            setCategories(list)
        })
    }
    
    const updateDimensions = (active) => {
        let selectedTab = ''
        if (isDesktopOrLaptop) {
            selectedTab = document.querySelectorAll('.tabs-item')[0];
        } else {
            selectedTab = document.querySelectorAll('.m-tabs-item')[0];
        }
        if (!selectedTab) return;
        let offMove = active * selectedTab.offsetWidth
        setIndicatorWidth(selectedTab.offsetWidth)
        setIndicatorOffset(offMove)
        // navigate(`/news?type=${active}`)
    }
    // 切换
    const handleTagActive = (id) => {
       
        const index = categories.findIndex(v=> {
            return v.id === id
        })
        setActive( index )
        let selectedTab = ''
        if (isDesktopOrLaptop) {
            selectedTab = document.querySelectorAll('.tabs-item')[index];
        } else {
            selectedTab = document.querySelectorAll('.m-tabs-item')[index];
        }
        if (!selectedTab) return;
        let offMove = index * selectedTab.offsetWidth
        setIndicatorOffset(offMove)
        setPn(1)
        setTypeId(id)
    }
    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a>{t("news.news_prev")}</a>;
        }
        if (type === 'next') {
            return <a>{t("news.news_next")}</a>;
        }
        return originalElement;
    };

    // 列表请求
    const fetch_get_list = (category, propPn) => {
        GET_NEWS_LIST({ language: sharedData['lang'], pn: propPn, category: category }).then(res => {
            if (res.error_code != 0) {
                console.log('error')
                return
            }
            setList(res.list.list.map(v=> {
                return {
                    ...v,
                    category
                }
            }))
            setTotal(res.page.totalCnt)
            setBannerList(res.list.banner.map(v=> {
                return {
                    ...v,
                    category
                }
            }))
            scroll.scrollToTop({
                duration: 0,
            })
        })
    }
    // 分页切换
    const onChange = (pageNumber) => {
        setPn(pageNumber)
        fetch_get_list(typeId, pageNumber)
    }
    const contentStyle = {
        margin: 0,
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };
    return (
        <div className='bg-gray' id='w-news'>
            {
                isDesktopOrLaptop && (
                    <div>
                        {
                             bannerList && bannerList.length ===0? (
                                <div 
                                    className='news-wrap-banner' 
                                >
                                    <img style={{zIndex: 0}} src='https://statres.wk1762.com/imgs/new-default.jpg' />
                                     <h2 style={{zIndex: 1}}>NEWS</h2>
                                     <p style={{zIndex: 1}} key={sharedData['lang']}><Texty type="bottom" >{t('news.banner_title')}</Texty> </p>
                                </div>
                             ): (
                                <Carousel autoplay dots={{className: 'news-dots'}}>
                                    {
                                        bannerList && bannerList.map((item, index)=>(
                                            <div 
                                                className='news-wrap-banner' 
                                                key={index}
                                                onClick={ ()=>goInfo(item.id, item.category) }
                                            >
                                                {/* <h2>{item['title']}</h2>
                                                <p>{item['sub_title']}</p> */}
                                                <img src={item.large_img_url} />
                                            </div>
                                        ))
                                    }
                                    {/* <div className='news-wrap-banner'>
                                        <h2>NEWS</h2>
                                        <p>为您推荐公司及行业的新闻资讯，随时了解行业动态</p>
                                    </div>
                                    <div className='news-wrap-banner'>
                                        <h2>NEWS</h2>
                                        <p>为您推荐公司及行业的新闻资讯，随时了解行业动态</p>
                                    </div> */}
                                </Carousel>
                             )
                        }
                       
                        <div className='container'>
                            <div className='news-tabs wow fadeInUp'>
                                <div className='news-tabs-flex'>
                                    {categories.map((item, index)=> {
                                        return (
                                            <div key={item.id} className={`tabs-item ${typeId == item.id ? 'active' : ''}`} onClick={() => handleTagActive( item.id )}>
                                                {item.name}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div
                                    className='active-border'
                                    style={{ width: indicatorWidth + 'px', transform: 'translateX(' + indicatorOffset + 'px)' }}
                                ></div>
                            </div>
                            <div className='news-tabs-connnet wow fadeInUp'>
                                <div className='news-tabs-connnet-list'>
                                    {
                                        list && list.map((item, index) => (
                                            <div className='connnet-list-item' key={index} onClick={ ()=>goInfo(item.id, item.category)  }>
                                                <div className='left'>
                                                    <img src={item['img_url']} alt="pic" />
                                                </div>
                                                <div className='right'>
                                                    <h4>{item['title']}</h4>
                                                    <span>{item['time']}</span>
                                                    <p>{item['sub_title']}</p>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {/* <div className='connnet-list-item'>
                                        <div className='left'>
                                            <img src={imageBusinessPaths['businessItem1']} alt="pic" />
                                        </div>
                                        <div className='right'>
                                            <h4>新闻</h4>
                                            <span>资讯 2023-10-23 13:54:23</span>
                                            <p>作为智能手机生态领域重要的分发渠道，硬核联盟覆盖逾8亿手机用户，逐渐成长为手游行业的有力发声者。同时通过月明星、超明星、硬核之夜、黑石奖等事件，吸引了海量参与者，建立起可靠的品牌形象。</p>
                                        </div>
                                    </div>
                                    <div className='connnet-list-item'>
                                        <div className='left'>
                                            <img src={imageBusinessPaths['businessItem1']} alt="pic" />
                                        </div>
                                        <div className='right'>
                                            <h4>新闻</h4>
                                            <span>资讯 2023-10-23 13:54:23</span>
                                            <p>作为智能手机生态领域重要的分发渠道，硬核联盟覆盖逾8亿手机用户，逐渐成长为手游行业的有力发声者。同时通过月明星、超明星、硬核之夜、黑石奖等事件，吸引了海量参与者，建立起可靠的品牌形象。</p>
                                        </div>
                                    </div> */}
                                </div>
                                <div className='wk-new-pagination'>
                                    <Pagination defaultCurrent={1} defaultPageSize={5} current={pn} total={total} itemRender={itemRender} onChange={onChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            {
                !isDesktopOrLaptop && (
                    <div>
                        {
                            bannerList && bannerList.length ===0 ? (
                                <div className='m-news-wrap-banner' >
                                    <h2>NEWS</h2>
                                    <p>{t('news.banner_title')}</p>
                                    <img style={{zIndex: 0}} src='https://statres.wk1762.com/imgs/m-new-default.jpg' />
                                </div>
                            ): (
                                <Carousel autoplay  dots={{className: 'news-dots'}}>
                                    {
                                        bannerList && bannerList.map((item, index)=>(
                                            <div className='m-news-wrap-banner' key={index} onClick={ ()=>goInfo(item.id, item.category)  }>
                                                {/* <h2>{item['title']}</h2>
                                                <p>{item['sub_title']}</p> */}
                                                <img src={item.m_large_img_url} />
                                            </div>
                                        ))
                                    }
                                    {/*  <div className='m-news-wrap-banner'>
                                    <h2>NEWS</h2>
                                    <p>为您推荐公司及行业的新闻资讯，随时了解行业动态</p>
                                    </div>*/}
                                </Carousel>
                            )
                        }
                        <div className='container'>
                            <div className='m-news-tabs wow fadeInUp'>
                                <div className='m-news-tabs-flex'>
                                    {/* <div className={`m-tabs-item ${active == 2 ? 'active' : ''}`} onClick={() => handleTagActive(2)}>
                                        {t('news.news_sub_business')}
                                    </div> */}
                                    {categories.map((item, index)=> {
                                        return (
                                            <div key={item.id} className={`m-tabs-item ${typeId == item.id ? 'active' : ''}`} onClick={() => handleTagActive(item.id )}>
                                                {item.name}
                                            </div>
                                        )
                                    })}
                                </div>
                                <div
                                    className='m-active-border'
                                    style={{ width: indicatorWidth + 'px', transform: 'translateX(' + indicatorOffset + 'px)' }}
                                ></div>
                            </div>
                            <div className='m-news-tabs-connnet wow fadeInUp'>
                                <div className='m-news-tabs-connnet-list'>
                                    {
                                        list && list.map((item, index) => (
                                            <div className='m-connnet-list-item' onClick={ ()=>goInfo(item.id, item.category) }>
                                                <div className='right'>
                                                    <h4>{item['title']}</h4>
                                                    <span>{item['time']}</span>
                                                </div>
                                                <div className='left'>
                                                    <img src={item['img_url']} alt="pic" />
                                                </div>
                                            </div>
                                        ))
                                    }
                                    {/* <div className='m-connnet-list-item'>
                                        <div className='right'>
                                            <h4>新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻新闻</h4>
                                            <span>资讯 2023-10-23 13:54:23</span>
                                        </div>
                                        <div className='left'>
                                            <img src={imageBusinessPaths['businessItem1']} alt="pic" />
                                        </div>
                                    </div>
                                    <div className='m-connnet-list-item'>
                                        <div className='right'>
                                            <h4>新闻</h4>
                                            <span>资讯 2023-10-23 13:54:23</span>
                                        </div>
                                        <div className='left'>
                                            <img src={imageBusinessPaths['businessItem1']} alt="pic" />
                                        </div>
                                    </div> */}
                                </div>
                                <div className='wk-new-pagination wow fadeInUp'>
                                    <Pagination defaultCurrent={1} current={pn} defaultPageSize={5} total={total} size='small' itemRender={itemRender} onChange={onChange} />
                                </div>

                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}
export default News