import { Avatar, Button, List, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { GET_INV_FINANCIAL } from '../../services/data.js'
import { useMyContext } from '../../layouts/index';
import { formatDate } from '../../utils/index.js'
import { useTranslation, Trans } from 'react-i18next';
const count = 3;
const MoreInfo = () => {
  const sharedData = useMyContext(); //共享语言切换
  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);
  const [ hasData, setHasData ] = useState(true);
  const { t, i18n } = useTranslation();
  const [ dataQuery, setDataQuery ] = useState({
    pn: 1,
    rn: 10
  })
  useEffect(() => {
    setInitLoading(true)
    setHasData(true)
    getList((list)=> {
      setInitLoading(false);
      setData(list);
      setList(list);
    },{ ...dataQuery, pn: 1  })
  }, [sharedData['lang']]);
  const getList = async (cb, p)=> {
    const dataParams = (p || dataQuery)
    let params = {language:sharedData['lang'], type: 2, ...dataParams};
    let lists = [];
    try {
      let { list } = await GET_INV_FINANCIAL(params);
      lists = list.list;
      const { totalCnt, pageNum, pageSize } = list.page
      // 如果没更多数据时 不显示加载按钮
      if (pageNum*pageSize>=totalCnt) {
        setHasData(false)
      }else {
        setDataQuery({
          ...dataParams,
          pn: dataParams.pn + 1,
        })
      }
    } finally {
      cb&&cb(lists)
    }
  }
  const onLoadMore = () => {
    setLoading(true);
    setList(
      data.concat(
        [...new Array(count)].map(() => ({
          loading: true,
          name: {},
          picture: {},
        })),
      ),
    );
    getList((list)=>{
      const newData = data.concat(list);
      setData(newData);
      setList(newData);
      setLoading(false);
      // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
      // In real scene, you can using public method of react-virtualized:
      // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
      window.dispatchEvent(new Event('resize'));
    })
  };
  const loadMore =
    !initLoading && !loading&&hasData ? (
      <div
        style={{
          textAlign: 'left',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
      >
        <Button onClick={onLoadMore} type='link' style={{padding: 0}}>{t("investor.inv_more")}</Button>
      </div>
    ) : null;
  return (
    <List
      className="demo-loadmore-list"
      loading={initLoading}
      itemLayout="horizontal"
      loadMore={loadMore}
      dataSource={list}
      split={false}
      renderItem={(item) => (
        <List.Item
        >
          <Skeleton avatar title={false} loading={item.loading} active>
            <List.Item.Meta
              avatar={null}
              title={<a href={item.url} target="_blank">{item.title}</a>}
              description={null}
            />
            <div className='times'>{formatDate(item.ctime, "{y}/{m}/{d}")}</div>
          </Skeleton>
        </List.Item>
      )}
    />
  );
};
export default MoreInfo;