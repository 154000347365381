import React, { useState, useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { useSpring, animated,useInView  } from 'react-spring';
import { useTranslation, Trans } from 'react-i18next';
import WOW from 'wowjs';
import Texty from 'rc-texty';
import { useMyContext } from '../../layouts/index';
import { GET_INFO_DEVELOPMENT } from '../../services/data.js'
import { STATIC_URL } from '../../ip.js'
import { flushSync } from 'react-dom'
// 
import AnimatedNum from './animatedNum.jsx'
import InView from './InView'

import { LogoImg, imagePaths, imageHonorPaths } from './exportImg.js'

import animType from 'rc-texty/lib/animTypes';
import 'rc-texty/assets/index.css';
const ChevronLeft = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
</svg>

const ChevronRight = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
</svg>



const Home = () => {
    const sharedData = useMyContext(); //共享语言切换
    const [list, setList] = useState([])
    const [isHovered, setHovered] = useState(false) // 服务客户
    const [honorActive, setHonorActive] = useState(0) // 企业荣誉 切换
    const [historySlate, setHistorySlate] = useState('translateX(0)')
    const [honorSlate, setHonorSlate] = useState('translateX(0)')
    const [historyRightBtn, setHistoryRightBtn] = useState(0)
    const { t, i18n } = useTranslation();
    const [ showContent, setShowContent ] = useState(0)
    const isShowView = useRef(false)
    const springs = useSpring({
        from: { x: 0 },
        to: { x: 900 },
    })
    

    // 小于768 切换移动端
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    })
    useEffect(() => {
        let wows = new WOW.WOW({
            boxClass: 'wow',            // 默认的WOW容器类名
            animateClass: 'animated',   // 默认的CSS动画类名
            offset: 0,                  // 触发动画的元素距离可视窗口底部的偏移量
            mobile: true,               // 在移动设备上是否启用动画
            live: true,                 // 异步加载内容时是否重新初始化WOW
            scrollContainer: null
        });
        wows.init({
            delay: '0.3s',              // 触发动画前的延时
            duration: '2s',           // 动画持续时间
        });
    }, []);
    function viewFnCallback(v) {
        if (v&&!isShowView.current) {
            flushSync(()=>{
                setShowContent(list.length - 5)
            })
            setTimeout(()=>srcollToRight())
            isShowView.current = true
        }
    }
    function mViewFnCallback(v) {
        if ( v&&!isShowView.current) {
            const dom = document.querySelector('#w-fzlc .m-history-list');
            const w = dom.offsetWidth
            dom.scrollLeft = w * (list.length - 1)
            isShowView.current = true
        }
    }
    useEffect(()=> {
        isShowView.current = false
    }, [isDesktopOrLaptop])
    
    useEffect(() => {
        // handleLoadedMetadata()
        // 移动端使用
        const srcollElements = document.querySelector('.m-history-list');
        const fn = () => {
            addStyleOnScroll()
        }
        if (srcollElements) {
            srcollElements.addEventListener('scroll', fn)
        };
        return ()=> {
            if (srcollElements) {
                srcollElements.removeEventListener('scroll', fn)
            }
        }
    }, [ isDesktopOrLaptop ])
    useEffect(()=>{
        // var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        // if (isSafari) {
        //     console.log("用户正在使用 Safari 浏览器");
        //     handleLoadedMetadata()
        // }else{
        //     handleLoadedMetadata()
        // }
        handleLoadedMetadata()
    },[])

    useEffect(() => {
        fetch_get_list()
    }, [sharedData['lang']])
    const isElementInHorizontalViewport = (el) => {
        const rect = el.getBoundingClientRect();
        const viewportWidth = window.innerWidth || document.documentElement.clientWidth;
        return (
            rect.left >= 0 &&
            rect.right <= viewportWidth
        );
    }
    const addStyleOnScroll = () => {
        const targetElements = document.querySelectorAll('.m-history-item');
        targetElements.forEach((el) => {

            if (isElementInHorizontalViewport(el)) {
                console.log('00', isElementInHorizontalViewport(el), el)
                el.classList.add('active');
            } else {
                el.classList.remove('active');
            }
        });
    }

    const handleLoadedMetadata = (event) => {
        const el_video = document.getElementById('wk-video')
        if (el_video) {
            let videoPlay = el_video.play();
            videoPlay.then(()=>{
                console.log('可以自动播放');
            }).catch((err)=>{
                console.log(err);
                console.log("不允许自动播放");
                el_video.play();
            });
            // setTimeout(() => {
            //     el_video.play()
            // }, 20000);
        }
    };
    const arrayToMap = Array.from({ length: 44 }, (_, index) => index + 1);
    const getReduceList = (array) => {
        return array.reduce((result, item, index) => {
            const groupIndex = Math.floor(index / 11);
            if (!result[groupIndex]) {
                result[groupIndex] = [];
            }
            result[groupIndex].push(item);
            return result;
        }, []);
    }
    const groupedArray = getReduceList(imagePaths)

    // 企业荣誉切换
    const handleHonorTigger = (index) => {
        let positioning = `translateX(-${index * 100}%)`
        setHonorActive(index)
        setHonorSlate(positioning)
    }

    // 发展历程 请求
    const fetch_get_list = () => {
        GET_INFO_DEVELOPMENT({ language: sharedData['lang'] }).then((res) => {
            if (res.error_code != 0) {
                return
            };
            flushSync(()=> {
                setList(res.list.map(v=> {
                    return {
                        ...v,
                        show: false
                    }
                }));
            })
            
        })
    }
    // 发展历程 左侧按钮
    const srcollToLeft = () => {
        let index = showContent - 5;
        if (index < 0) {
            index = 0;
        }
        setPositionStyle(index)
    }
    // 发展历程 右侧按钮
    const srcollToRight = () => {
        let l = list.length -5, index = 0;
        index += (showContent + 5);
        if (index > l) {
            index = l;
        }
        setPositionStyle(index)
    }
    const setPositionStyle = (offIndex)=> {
        let historyOffright = document.querySelectorAll('.history-item')[0].offsetWidth;
        let offsrcoll = offIndex * historyOffright
        // 延迟触发影藏元素  等动画执行完毕
        setShowContent(offIndex)
        let positioning = `translateX(-${offsrcoll}px)`
        setHistorySlate(positioning)
    }
    return (
        <div>
            {
                isDesktopOrLaptop && (
                    <div>
                        <div className='homepage-video-wrap' id='w-top'>
                            {/* <img src={playPng} alt="" /> */}
                            <video
                                id="wk-video"
                                controls
                                preload="auto"
                                loop
                                playsInline
                                muted
                                poster={ STATIC_URL+'/imgs/video-poster.png'}
                            >
                                <source src={STATIC_URL+'/video/gongquhunjian_1080.mp4'} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>

                            <div className='overlay'>
                                <div className='overlay-wrapper'>
                                    <div className='container overlay-content'>
                                        <div className='overlay-item'>
                                            <h4>

                                                {
                                                    sharedData['lang'] == 1 ? (

                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_mission')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 2 ? (

                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_mission')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 3 ? (

                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_mission')}
                                                        </Texty>
                                                    ) : null
                                                }
                                            </h4>
                                            <p>
                                                {
                                                    sharedData['lang'] == 1 ? (

                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_mission')}
                                                        </Texty>
                                                    ) : null
                                                }

                                                {
                                                    sharedData['lang'] == 2 ? (

                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_mission')}
                                                        </Texty>
                                                    ) : null
                                                }

                                                {
                                                    sharedData['lang'] == 3 ? (

                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_mission')}
                                                        </Texty>
                                                    ) : null
                                                }

                                            </p>
                                        </div>
                                        <div className='overlay-item'>
                                            <h4>
                                                {
                                                    sharedData['lang'] == 1 ? (

                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_vision')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 2 ? (

                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_vision')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 3 ? (

                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_vision')}
                                                        </Texty>
                                                    ) : null
                                                }
                                            </h4>
                                            <p>
                                                {
                                                    sharedData['lang'] == 1 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_vision')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 2 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_vision')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 3 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_vision')}
                                                        </Texty>
                                                    ) : null
                                                }

                                            </p>
                                        </div>
                                        <div className='overlay-item'>
                                            <h4>
                                                {
                                                    sharedData['lang'] == 1 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_values')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 2 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_values')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 3 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_values')}
                                                        </Texty>
                                                    ) : null
                                                }

                                            </h4>
                                            <p>
                                                {
                                                    sharedData['lang'] == 1 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_values')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 2 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_values')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 3 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_values')}
                                                        </Texty>
                                                    ) : null
                                                }

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='homepage-product' id='w-fwnr'>
                            <div className='homepage-product-header container'>

                                <h3 className='home-h3-title wow fadeInUp'>
                                    {t('home.service')}
                                </h3>
                                <p className='wow fadeInUp'>
                                    {t('home.service_sub_1')}
                                    {t('home.service_sub_2')}
                                </p>
                            </div>
                            <div className='container'>
                                <div className="row">
                                    <div className="col-4">

                                        <div
                                            className='product-item product-item-bg-1 wow fadeInUp'>
                                            <h5>
                                                {t('home.service_data_title')}
                                            </h5>
                                            {/* className='value' */}
                                            <div className='product-item-number' >
                                            
                                                <AnimatedNum classNameValue='value' value={90}/>
                                                <span className='subscript'>%{t('home.service_data_over')}</span>
                                            </div>
                                            <p>
                                                {t('home.service_data_android')}
                                            </p>
                                            <div className='product-item-number'>
                                                <AnimatedNum classNameValue='value' value={10}/>
                                                <span className='subscript'>{t('home.service_data_billion')}+</span>
                                            </div>
                                            <p>
                                                {t('home.service_data_existing')}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-8">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className='product-item product-item-bg-2 wow fadeInUp'>
                                                    <h5>
                                                        {t('home.service_industry')}
                                                    </h5>
                                                    <p>
                                                        {t('home.service_sub_industry')}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='product-item  product-item-bg-3 wow fadeInUp'>
                                                    <h5>
                                                        {t('home.service_intelligent')}
                                                    </h5>
                                                    <p>
                                                        {t('home.service_sub_intelligent')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row" style={{ marginTop: '20px' }}>
                                            <div className="col-6">

                                                <div className='product-item product-item-bg-4 wow fadeInUp'>
                                                    <h5>
                                                        {t('home.service_comprehensive')}
                                                    </h5>
                                                    <p>
                                                        {t('home.service_sub_comprehensive')}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className='product-item  product-item-bg-5 wow fadeInUp'>
                                                    <h5>
                                                        {t('home.service_distinctive')}
                                                    </h5>
                                                    <p>
                                                        {t('home.service_sub__distinctive')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='homepage-product-data' id='w-hxsj'>
                            <div className='container'>
                                <h3 className='home-h4-title wow fadeInUp'>
                                    {t('home.service_core_title')}
                                </h3>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className='product-item wow fadeInUp'>
                                                    <div className='product-item-number'>
                                                        <AnimatedNum classNameValue='value' value={23.01}/>
                                                        <span className='subscript'>{t('home.service_core_sub')}</span>
                                                    </div>
                                                    <p>
                                                        {t('home.service_core_year')}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className='product-item  wow fadeInUp'>
                                                    <div className='product-item-number'>
                                                        <AnimatedNum classNameValue='value' value={300}/>
                                                        <span className='subscript'>+</span>
                                                    </div>
                                                    <p>
                                                        {t('home.service_core_employees')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='homepage-media' id='w-hzmt'>
                            <div className='homepage-product-header container'>
                                <h3 className='home-h3-title wow fadeInUp'>
                                    {t('home.service_media')}
                                </h3>
                                <p className='wow fadeInUp'>
                                    {t('home.service_sub_media')}
                                </p>
                            </div>
                            <div className='container rela'>
                                <div className='media-item wow fadeInUp'>
                                    <img src={LogoImg['oppoImg']} alt='oppo' />
                                </div>
                                <div className='media-item wow fadeInUp'>
                                    <img src={LogoImg['vivoImg']} alt='vivo' />
                                </div>
                                <div className='media-item wow fadeInUp'>
                                    <img src={LogoImg['huaweiImg']} alt='huawei' />
                                </div>
                                <div className='media-item wow fadeInUp'>
                                    <img src={LogoImg['miImg']} alt='mi' />
                                </div>
                                <div className='media-item wow fadeInUp'>
                                    <img src={LogoImg['appleImg']} alt='apple' />
                                </div>
                                <div className='media-item wow fadeInUp'>
                                    <img src={LogoImg['qiyImg']} alt='QIY' />
                                </div>
                                <div className='media-item wow fadeIn'>
                                    <img src={LogoImg['honorImg']} alt='HONOR' />
                                </div>
                            </div>
                        </div>

                        <div className='homepage-custom' id='w-fukh'>
                            <div className='homepage-product-header container'>
                                <h3 className='home-h3-title wow fadeInUp'>
                                    {t('home.service_serving')}
                                </h3>
                                <p className='wow fadeInUp'>
                                    {t('home.service_sub_serving')}
                                </p>
                            </div>
                            <div className='container custom-rela wow fadeInUp'>
                                <div className='horizontal-scroll'>
                                    {groupedArray.map((group, groupIndex) => (
                                        <div key={groupIndex} className="homepage-custom-flex">
                                            {group.map((path, childrenIndex) => (
                                                <div className="custom-item" key={childrenIndex}>
                                                    <img src={path} alt='custom' />
                                                </div>
                                            ))}
                                            {group.map((path, childrenIndex) => (
                                                <div className="custom-item" key={childrenIndex}>
                                                    <img src={path} alt='custom' />
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <InView viewFn={viewFnCallback}>
                            <div className='homepage-history' id='w-fzlc'>
                                <div className='homepage-product-header container'>
                                    <h3 className='home-h3-title wow fadeInUp'>
                                        {t('home.service_sub_development')}
                                    </h3>
                                </div>
                                <div className='container rela'>
                                    <div className={`chevronLeft wow fadeInUp ${showContent ===0 ? 'disabled' : ''}`} onClick={srcollToLeft}>
                                        {ChevronLeft}
                                    </div>
                                    <div className={`chevronRight wow fadeInUp ${showContent ===list.length -5 ? 'disabled' : ''}`} onClick={srcollToRight}>
                                        {ChevronRight}
                                    </div>
                                    <div className='history-hidden wow fadeInUp'>
                                        <div className='history-list'  style={{ transform: historySlate }}>
                                            {
                                                list && list.map((item, index) => {
                                                    return (
                                                        <div className="history-item" key={index}>
                                                            <div className='title-year'>{item['title']}</div>
                                                            <div className='history-item-info'>
                                                                <div className='history-item-info-border-left'></div>
                                                                <ul>
                                                                    {
                                                                        item['content'] && item['content'].map((itemCon, itemIndex) => {
                                                                            return showContent<index+1&&index<showContent+5? (
                                                                                <li key={itemIndex}>
                                                                                    <span>{itemCon}</span>
                                                                                </li>
                                                                            ) :null
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                )
                                            }
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </InView>
                        <div className='homepage-honor' id='w-qyry'>
                            <div className='homepage-product-header container'>
                                <h3 className='home-h3-title wow fadeInUp'>
                                    {t('home.service_sub_honors')}
                                </h3>
                            </div>
                            <div className='container honor-container'>
                                <div className='honor-container-flex  wow fadeInUp' style={{ transform: honorSlate }}>
                                    <div className='honor-list'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg1']} alt="honor" />
                                                    {/* <div className='honer-item-text'>2022 360优质伙伴奖</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_1')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg2']} alt="honor" />
                                                    {/* <div className='honer-item-text'>CFS第十一届2022(行业)影响力品牌</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_2')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg3']} alt="honor" />
                                                    {/* <div className='honer-item-text'>2022混沌学院创新力先锋企业</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_3')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg4']} alt="honor" />
                                                    {/* <div className='honer-item-text'>2022上半年度vivo KA渠道业务优秀代理商</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_4')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg5']} alt="honor" />
                                                    {/* <div className='honer-item-text'>2022第二届朝阳区“凤鸣计划”高成长企业</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_5')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg6']} alt="honor" />
                                                    {/* <div className='honer-item-text'>第七届智通财经上市公司评选“最佳TMT公司”</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_6')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg7']} alt="honor" />
                                                    {/* <div className='honer-item-text'>2022 EDGE AWARDS“年度潜在价值企业奖”</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_7')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg8']} alt="honor" />
                                                    {/* <div className='honer-item-text'>2022 OPPO游戏行业核心服务商</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_8')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='honor-list'>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg9']} alt="honor" />
                                                    {/* <div className='honer-item-text'>欢聚高新证书2022</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_9')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg10']} alt="honor" />
                                                    {/* <div className='honer-item-text'>2022专精特新</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_10')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg11']} alt="honor" />
                                                    {/* <div className='honer-item-text'>三体系认证-环境管理-中</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_11')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg12']} alt="honor" />
                                                    {/* <div className='honer-item-text'>三体系认证-职业健康安全管理-中</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_12')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg13']} alt="honor" />
                                                    {/* <div className='honer-item-text'>三体系认证-质量管理-中</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_13')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg14']} alt="honor" />
                                                    {/* <div className='honer-item-text'>2022双体系认证-服务管理-中</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_14')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg15']} alt="honor" />
                                                    {/* <div className='honer-item-text'>2022双体系认证-信息安全管理-中</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_15')}</div>
                                                </div>
                                            </div>
                                            <div className='col-3'>
                                                <div className='honer-item'>
                                                    <img src={imageHonorPaths['honorItemImg16']} alt="honor" />
                                                    {/* <div className='honer-item-text'>CFS第十一届2022卓越影响力企业家奖</div> */}
                                                    <div className='honer-item-text'>{t('home.service_honors_text_16')}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='honor-btn'>
                                    <div className={`honor-btn-item ${honorActive == 0 ? 'active' : ''}`} onClick={() => handleHonorTigger(0)}></div>
                                    <div className={`honor-btn-item ${honorActive == 1 ? 'active' : ''}`} onClick={() => handleHonorTigger(1)}></div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='homepage-directorate' id='w-dsh'>
                            <div className='homepage-product-header container'>
                                <h3 className='home-h3-title wow fadeInUp' id='jobs' name="jobs">
                                    {t('home.service_sub_board')}
                                </h3>
                            </div>
                            <div className='container'>
                                <div className='directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_gao')}</h3>
                                    <h5>{t('home.service_board_sub_gao')}</h5>
                                    <p>{t('home.service_board_sub_gao_con')}</p>
                                </div>
                                <div className='directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_jiang')}</h3>
                                    <h5>{t('home.service_board_sub_jiang')}</h5>
                                    <p>{t('home.service_board_sub_jiang_con')}</p>
                                </div>
                                <div className='directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_yu')}</h3>
                                    <h5>{t('home.service_board_sub_yu')}</h5>
                                    <p>{t('home.service_board_sub_yu_con')}</p>
                                </div>
                                <div className='directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_nie')}</h3>
                                    <h5>{t('home.service_board_sub_nie')}</h5>
                                    <p>{t('home.service_board_sub_nie_con')}</p>
                                </div>
                                <div className='directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_chen')}</h3>
                                    <h5>{t('home.service_board_non')}</h5>
                                    <p>{t('home.service_board_sub_chen_con')}</p>
                                </div>
                                <div className='directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_jin')}</h3>
                                    <h5>{t('home.service_board_non')}</h5>
                                    <p>{t('home.service_board_sub_jin_con')}</p>
                                </div>
                                <div className='directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_li')}</h3>
                                    <h5>{t('home.service_board_non')}</h5>
                                    <p>{t('home.service_board_sub_li_con')}</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                )
            }
    
            {
                !isDesktopOrLaptop && (
                    <div>
                        <div className='m-homepage-video-wrap' id='w-top'>
                            {/* <img src={playPng} alt="" /> */}
                            {/* <video
                                id="wk-video"
                                preload="auto"
                                loop
                                playsInline
                                autoPlay
                                muted
                                poster={playPng}
                            >
                                <source src={playVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video> */}

                            <div className='m-overlay'>
                                <div className='m-overlay-wrapper'>
                                    <div className='container m-overlay-content'>
                                        <div className='m-overlay-item'>
                                            <h4>
                                                {
                                                    sharedData['lang'] == 1 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_mission')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 2 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_mission')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 3 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_mission')}
                                                        </Texty>
                                                    ) : null
                                                }

                                            </h4>
                                            <p>
                                                {
                                                    sharedData['lang'] == 1 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_mission')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 2 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_mission')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 3 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_mission')}
                                                        </Texty>
                                                    ) : null
                                                }

                                            </p>
                                        </div>
                                        <div className='m-overlay-item'>
                                            <h4>
                                                {
                                                    sharedData['lang'] == 1 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_vision')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 2 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_vision')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 3 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_vision')}
                                                        </Texty>
                                                    ) : null
                                                }
                                            </h4>
                                            <p>
                                                {
                                                    sharedData['lang'] == 1 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_vision')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 2 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_vision')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 3 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_vision')}
                                                        </Texty>
                                                    ) : null
                                                }
                                            </p>
                                        </div>
                                        <div className='m-overlay-item'>
                                            <h4>
                                                {
                                                    sharedData['lang'] == 1 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_values')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 2 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_values')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 3 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_values')}
                                                        </Texty>
                                                    ) : null
                                                }
                                            </h4>
                                            <p>
                                                {
                                                    sharedData['lang'] == 1 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_values')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 2 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_values')}
                                                        </Texty>
                                                    ) : null
                                                }
                                                {
                                                    sharedData['lang'] == 3 ? (
                                                        <Texty
                                                            type="left"
                                                            mode="smooth"
                                                        >
                                                            {t('home.corporate_sub_values')}
                                                        </Texty>
                                                    ) : null
                                                }

                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='m-homepage-product' id='w-fwnr'>
                            <div className='m-homepage-product-header container'>
                                <h3 className='m-home-h3-title wow fadeInUp'>
                                    {t('home.service')}
                                </h3>
                                <p className='wow fadeInUp'>
                                    {t('home.service_sub_1')}{t('home.service_sub_2')}
                                </p>
                            </div>
                            <div className='container'>
                                <div className='m-product-item product-item-bg-1 wow fadeInUp'>
                                    <h5>
                                        {t('home.service_data_title')}
                                    </h5>
                                    <div className='m-home-flex'>
                                        <div>
                                            <div className='product-item-number'>
                                                <span className='value'>90</span>
                                                <span className='subscript'>%{t('home.service_data_over')}</span>
                                            </div>
                                            <p>
                                                {t('home.service_data_android')}
                                            </p>
                                        </div>

                                        <div className='right'>
                                            <div className='product-item-number'>
                                                <span className='value'>10</span>
                                                <span className='subscript'>{t('home.service_data_billion')}+</span>
                                            </div>
                                            <p>
                                                {t('home.service_data_existing')}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='m-product-item product-item-bg-2 wow fadeInUp'>
                                    <h5>
                                        {t('home.service_industry')}
                                    </h5>
                                    <p>
                                        {t('home.service_sub_industry')}
                                    </p>
                                </div>
                                <div className='m-product-item  product-item-bg-3 wow fadeInUp'>
                                    <h5>
                                        {t('home.service_intelligent')}
                                    </h5>
                                    <p>
                                        {t('home.service_sub_intelligent')}
                                    </p>
                                </div>
                                <div className='m-product-item product-item-bg-4 wow fadeInUp'>
                                    <h5>
                                        {t('home.service_comprehensive')}
                                    </h5>
                                    <p>
                                        {t('home.service_sub_comprehensive')}
                                    </p>
                                </div>
                                <div className='m-product-item product-item-bg-5 wow fadeInUp'>
                                    <h5>
                                        {t('home.service_distinctive')}
                                    </h5>
                                    <p>
                                        {t('home.service_sub__distinctive')}
                                    </p>
                                </div>
                            </div>
                        </div>


                        <div className='m-homepage-product-data' id='w-hxsj'>
                            <div className='container'>
                                <h3 className='m-home-h4-title wow fadeInUp'>
                                    {t('home.service_core_title')}
                                </h3>
                                <div className="">
                                    <div className='m-product-item'>
                                        <div className='product-item-number wow fadeInUp'>
                                            <span className='value'>23.01</span>
                                            <span className='subscript'>{t('home.service_core_sub')}</span>
                                        </div>
                                        <p className='wow fadeInUp'>
                                            {t('home.service_core_year')}
                                        </p>
                                    </div>
                                    <div className='m-product-item'>
                                        <div className='product-item-number wow fadeInUp'>
                                            <span className='value'>300</span>
                                            <span className='subscript'>+</span>
                                        </div>
                                        <p className='wow fadeInUp'>
                                            {t('home.service_core_employees')}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className='m-homepage-media' id='w-hzmt'>
                            <div className='m-homepage-product-header container'>
                                <h3 className='m-home-h3-title wow fadeInUp'>
                                    {t('home.service_media')}
                                </h3>
                                <p className='wow fadeInUp'>
                                    {t('home.service_sub_media')}
                                </p>
                            </div>
                            <div className='container m-media-list'>
                                <div className='m-media-item wow fadeInUp'>
                                    <img src={LogoImg['oppoImg']} alt='oppo' />
                                </div>
                                <div className='m-media-item wow fadeInUp'>
                                    <img src={LogoImg['vivoImg']} alt='vivo' />
                                </div>
                                <div className='m-media-item wow fadeInUp'>
                                    <img src={LogoImg['huaweiImg']} alt='huawei' />
                                </div>
                                <div className='m-media-item wow fadeInUp'>
                                    <img src={LogoImg['miImg']} alt='mi' />
                                </div>
                                <div className='m-media-item wow fadeInUp'>
                                    <img src={LogoImg['appleImg']} alt='apple' />
                                </div>
                                <div className='m-media-item wow fadeInUp'>
                                    <img src={LogoImg['qiyImg']} alt='QIY' />
                                </div>
                                <div className='m-media-item wow fadeInUp'>
                                    <img src={LogoImg['honorImg']} alt='HONOR' />
                                </div>
                            </div>
                        </div>

                        <div className='m-homepage-custom' id='w-fukh'>
                            <div className='m-homepage-product-header container'>
                                <h3 className='m-home-h3-title wow fadeInUp'>
                                    {t('home.service_serving')}
                                </h3>
                                <p className='wow fadeInUp'>
                                    {t('home.service_sub_serving')}
                                </p>
                            </div>
                            <div className='container custom-rela'>
                                <div className='m-horizontal-scroll wow fadeInUp'>
                                    {groupedArray.map((group, groupIndex) => (
                                        <div key={groupIndex} className="m-homepage-custom-flex">
                                            {group.map((path, childrenIndex) => (
                                                <div className="m-custom-item" key={childrenIndex}>
                                                    <img src={path} alt='custom' />
                                                </div>
                                            ))}
                                            {group.map((path, childrenIndex) => (
                                                <div className="m-custom-item" key={childrenIndex}>
                                                    <img src={path} alt='custom' />
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <InView viewFn={mViewFnCallback}>
                            <div className='m-homepage-history' id='w-fzlc' >
                                <div className='m-homepage-product-header container'>
                                    <h3 className='m-home-h3-title wow fadeInUp'>
                                        {t('home.service_sub_development')}
                                    </h3>

                                </div>
                                <div className='container m-history-flex wow fadeInUp'>
                                    <div className='m-history-list'>
                                        {/* <div className='m-chevronLeft'>
                                            {ChevronLeft}
                                        </div>
                                        <div className='m-chevronRight'>
                                            {ChevronRight}
                                        </div> */}
                                        {
                                            list && list.map((item, index) => {
                                                return (
                                                    <InView className="m-history-item" viewFn={(show)=> {
                                                        setList(list.map((v, i)=> {
                                                            return index === i? {
                                                                ...v,
                                                                show
                                                            }: v
                                                        }))
                                                    }} key={index}>
                                                        <div className='m-title-year'>{item['title']}</div>
                                                        <div className='m-history-item-info'>
                                                            <div className='m-history-item-info-border-left'></div>
                                                            <ul>
                                                                {
                                                                    (
                                                                        item.show ? item['content'] && item['content']:
                                                                        item['content'].slice(0, 3)
                                                                    )
                                                                    .map((itemCon, itemIndex) => {
                                                                        return  (
                                                                            <li key={itemIndex}>
                                                                                <span>{itemCon}</span>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </InView>
                                                )
                                            }
                                            )
                                        }
                                    </div>

                                </div>
                            </div>
                        </InView>
                        

                        <div className='m-homepage-honor' id='w-qyry'>
                            <div className='m-homepage-product-header container'>
                                <h3 className='m-home-h3-title wow fadeInUp'>
                                    {t('home.service_sub_honors')}
                                </h3>
                            </div>
                            <div className='container m-honor-container wow fadeInUp'>
                                <div className='m-honor-container-flex' style={{ transform: honorSlate }}>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg1']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_1')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg2']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_2')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg3']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_3')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg4']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_4')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg5']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_5')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg6']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_6')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg7']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_7')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg8']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_8')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg9']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_9')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg10']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_10')}</div>
                                    </div>

                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg11']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_11')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg12']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_12')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg13']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_13')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg14']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_14')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg15']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_15')}</div>
                                    </div>
                                    <div className='m-honer-item'>
                                        <img src={imageHonorPaths['honorItemImg16']} alt="honor" />
                                        <div className='m-honer-item-text'>{t('home.service_honors_text_16')}</div>
                                    </div>
                                    <div className='m-honer-item-null'>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className='m-homepage-directorate' id='w-dsh'>
                            <div className='m-homepage-product-header container'>
                                <h3 className='m-home-h3-title wow fadeInUp'>
                                    {t('home.service_sub_board')}
                                </h3>
                            </div>
                            <div className='container'>
                                <div className='m-directorate-item  wow fadeInUp'>
                                    <h3>{t('home.service_board_gao')}</h3>
                                    <h5>{t('home.service_board_sub_gao')}</h5>
                                    <p>{t('home.service_board_sub_gao_con')}</p>
                                </div>
                                <div className='m-directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_jiang')}</h3>
                                    <h5>{t('home.service_board_sub_jiang')}</h5>
                                    <p>{t('home.service_board_sub_jiang_con')}</p>
                                </div>
                                <div className='m-directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_yu')}</h3>
                                    <h5>{t('home.service_board_sub_yu')}</h5>
                                    <p>{t('home.service_board_sub_yu_con')}</p>
                                </div>
                                <div className='m-directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_nie')}</h3>
                                    <h5>{t('home.service_board_sub_nie')}</h5>
                                    <p>{t('home.service_board_sub_nie_con')}</p>
                                </div>
                                <div className='m-directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_chen')}</h3>
                                    <h5>{t('home.service_board_non')}</h5>
                                    <p>{t('home.service_board_sub_chen_con')}</p>
                                </div>
                                <div className='m-directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_jin')}</h3>
                                    <h5>{t('home.service_board_non')}</h5>
                                    <p>{t('home.service_board_sub_jin_con')}</p>
                                </div>
                                <div className='m-directorate-item wow fadeInUp'>
                                    <h3>{t('home.service_board_li')}</h3>
                                    <h5>{t('home.service_board_non')}</h5>
                                    <p>{t('home.service_board_sub_li_con')}</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                )
            }
        </div>
    )
}
export default Home