import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n.js'
import './style/bootstrap.min.css';
import 'animate.css';
import 'wowjs/css/libs/animate.css';
import './style/wk_sytle.scss';
import './style/wk_sytle-m.scss';
import './style/index.scss';

const root = document.getElementById('root');
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
