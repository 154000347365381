
import React, { useState, createContext, useContext, useEffect } from 'react';
import { Link, Outlet, useNavigate, useLocation } from 'react-router-dom'
import { Link as AnLink, animateScroll as scroll } from "react-scroll";
import { Popover, Drawer, Affix, Collapse, BackTop } from 'antd';
import { useTranslation, Trans } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'
import { STATIC_URL } from '../ip.js'
import { GET_NEWS_CATEGORY } from '../services/data.js'
const qrPath = STATIC_URL + '/imgs/qr.png'

const geoAlt = <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-geo-alt" viewBox="0 0 16 16">
    <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z" />
    <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
</svg>

const telephone = <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
    <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
</svg>
const listIcon = <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
    <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
</svg>
const InlineLayoutBi = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
</svg>
const chevronBarUp = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" className="bi bi-chevron-bar-up" viewBox="0 0 16 16">
<path fillRule="evenodd" d="M3.646 11.854a.5.5 0 0 0 .708 0L8 8.207l3.646 3.647a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 0 0 0 .708zM2.4 5.2c0 .22.18.4.4.4h10.4a.4.4 0 0 0 0-.8H2.8a.4.4 0 0 0-.4.4z"/>
</svg>

const { Panel } = Collapse;

const MyContext = createContext();

const UserLayout = () => {
    const [lang, setLang] = useState(1)  // 1简体 2繁体 3英文
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [businessActive, setBusinessActive] = useState(-1)
    const [newsActive, setNewsActive] = useState(-1)
    const navigate = useNavigate();
    let location = useLocation()
    const {pathname, search} = location
    const [categories, setCategories] = useState([]);
    
    useEffect(()=>{
        const i = ['zh-CN', 'hk', 'en'].indexOf(i18n.language)+1
        setLang(i);
        getCategories(i)
    },[i18n])
    useEffect(() => {
        // url获取参数查询
      let params_url = window.location.href.split('?')
      let params_arr = null
      let obj = {}
      if (params_url.length > 1) {
          params_arr = params_url[1].split('&')
          params_arr.forEach((element) => {
              let kv = element.split('=')
              obj[kv[0]] = kv[1]
          })
          if (obj.length != 0 && obj['type'] !='') {
            if(obj['type'] !='') {
                if(pathname == '/business') {
                    console.log(obj['type'])
                    setBusinessActive(obj['type'])
                    setNewsActive(-1)
                }
                if(pathname == '/news'){
                    setBusinessActive(-1)
                }
            }else{
                setBusinessActive(-1)
                setNewsActive(-1)
            }
          }
      }
      
    }, [pathname, search])
    // 小于768 切换移动端 true 是pc
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 768px)'
    })

    const getCategories = async (lang)=> {
        const params = {
            language: lang
        }
        return await GET_NEWS_CATEGORY(params).then(res=> {
            const list = res.list.categories
            setCategories(list)
        })
    }
    const changLange = (index) => {
        let langarr = ''
        if (index == 1) {
            langarr = 'zh-CN'
        } else if (index == 2) {
            langarr = 'hk'
        } else if (index == 3) {
            langarr = 'en'
        }
        setLang(index)
        getCategories(index)
        i18n.changeLanguage(langarr)
    }

    const contentbusiness = (
        <div className='menu_sub'>
            <p className={`${businessActive == 0 ? 'active' : ''}`} onClick={()=>scrollToAnchor("w-bussiness", "/business?type=0")}>{t('footer.business_sub_hardcore')}</p>
            <p className={`${businessActive == 1 ? 'active' : ''}`} onClick={()=>scrollToAnchor("w-bussiness", "/business?type=1")}>{t('footer.business_sub_quick')}</p>
            <p className={`${businessActive == 2 ? 'active' : ''}`} onClick={()=>scrollToAnchor("w-bussiness", "/business?type=2")}>{t('footer.business_sub_golden')}</p>
            <p className={`${businessActive == 3 ? 'active' : ''}`} onClick={()=>scrollToAnchor("w-bussiness", "/business?type=3")}>{t('footer.business_sub_ads')}</p>
        </div>
    );

    const contentnew = (
        <div className='menu_sub'>
            <p className={`${newsActive == 0 ? 'active' : ''}`} onClick={()=> {
                newsCallback(0);
                navigate('/news?active=0')
            }}>{t('footer.news_sub_group')}</p>
            <p className={`${newsActive == 1 ? 'active' : ''}`} onClick={()=>{
                newsCallback(1);
                navigate('/news?active=1')
            }}>{t('footer.news_sub_alliance')}</p>
            <p className={`${newsActive == 2 ? 'active' : ''}`} onClick={()=>{
                newsCallback(2);
                navigate('/news?active=2')
            }}>{t('footer.news_sub_business')}</p>
        </div>
    );
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const scrollToAnchor = (anchor, url, active) => {
        newsCallback(active)
        let eles = document.body.querySelector(anchor)
        let eles_offsetTop = 0
        if(eles) {
            if (anchor === 'w-bussiness') {
                const { top } = eles.getBoundingClientRect()
                const h = document.documentElement.scrollTop;
                scroll.scrollTo(h + top - eles.clientHeight )
            }else {
                eles_offsetTop = eles.offsetTop - 80
                console.log(eles_offsetTop);
                scroll.scrollTo(eles_offsetTop)
            }
           
        }else{
            if(url) {
                if ( url.indexOf('investor') > -1 ) {
                    navigate(url +'#'+anchor);
                    return
                }else {
                    navigate(url)
                }
                setTimeout(()=>{
                    let eles = document.getElementById(anchor)
                    let eles_offsetTop = 0
                    if(eles) {
                        eles_offsetTop = eles.offsetTop - 80
                        scroll.scrollTo(eles_offsetTop)
                    }
                }, 100)
            }
        }
    };

    const mscrollToAnchor = (anchor, url, active) => {
        newsCallback(active)
        onClose()
        console.log('anchor', anchor)
        let eles = document.getElementById(anchor)
        let eles_offsetTop = 0
        if(eles) {
            eles_offsetTop = eles.offsetTop - 80
            scroll.scrollTo(eles_offsetTop)
        }else{
            if(url) {
                if ( url.indexOf('investor') > -1 ) {
                    navigate(url +'#'+anchor);
                    return
                }else {
                    navigate(url)
                }
                setTimeout(()=>{
                    let eles = document.getElementById(anchor)
                    let eles_offsetTop = 0
                    if(eles) {
                        console.log(eles.offsetTop)
                        eles_offsetTop = eles.offsetTop - 80
                        scroll.scrollTo(eles_offsetTop)
                    }
                }, 100)
            }
        }
    };
    let defCb = ()=> {}
    let newsCallback = (active)=>{
        if (typeof active === 'number') {
            setNewsActive(active)
            defCb(categories[active].id)
        }
    };
    const newsChangeCallback = (cb)=> {
        defCb = cb;
    }
    return (
        <MyContext.Provider value={{ lang: lang, newsChangeCallback }}>
            <div>
                {
                    isDesktopOrLaptop && (
                        <div className='header-wrapper s_footer' id='s_footer' name="s_footer">
                            <div className='container header-content'>
                                <div className='menu_wrapper'>
                                    <div className='wk_logo' onClick={()=>navigate('/')}></div>
                                    <div className='wk_menu'>
                                        <Link className={`wk_menu_item ${pathname == '/' ? 'active' : ''}`} to="/">{t('menu.home')}</Link>
                                        <Popover color="rgba(0,0,0,0.8)" content={contentbusiness} placement="bottom">
                                            <Link className={`wk_menu_item ${pathname == '/business' ? 'active' : ''}`} to="/business">
                                                {t('menu.business')}
                                            </Link>
                                        </Popover>
                                        <Popover color="rgba(0,0,0,0.8)" content={contentnew} placement="bottom">
                                            <Link className={`wk_menu_item ${pathname == '/news' ? 'active' : ''}`} to="/news">{t('menu.news')}</Link>
                                        </Popover>
                                        <Link className={`wk_menu_item ${pathname == '/investor' ? 'active' : ''}`} to="/investor">{t('menu.investor')}</Link>
                                        <Link className={`wk_menu_item ${pathname == '/jobs' ? 'active' : ''}`} to="/jobs">{t('menu.jobs')}</Link>
                                    </div>

                                </div>
                                <div className='wk_translate'>
                                    <span className={`wk_translate_item ${lang == 1 ? 'active' : ''}`} onClick={() => changLange(1)}>简</span>
                                    <span className={`wk_translate_item ${lang == 2 ? 'active' : ''}`} onClick={() => changLange(2)}>繁</span>
                                    <span className={`wk_translate_item ${lang == 3 ? 'active' : ''}`} onClick={() => changLange(3)}>En</span>
                                </div>
                            </div>

                        </div>
                    )
                }
                {
                    !isDesktopOrLaptop && (
                        <div className='m-header-wrapper'>
                            <div className='container m-header-content'>
                                <div className='m-wk_logo'></div>
                                <div className='m-wk_translate'>
                                    <span className={`m-wk_translate_item ${lang == 1 ? 'active' : ''}`} onClick={() => changLange(1)}>简</span>
                                    <span className={`m-wk_translate_item ${lang == 2 ? 'active' : ''}`} onClick={() => changLange(2)}>繁</span>
                                    <span className={`m-wk_translate_item ${lang == 3 ? 'active' : ''}`} onClick={() => changLange(3)}>En</span>
                                    <div onClick={showDrawer}>{listIcon}</div>
                                </div>
                            </div>
                        </div>
                    )
                }
                <div>
                    {
                        isDesktopOrLaptop&& (
                            <BackTop>
                                <div className='adf' >
                                    {chevronBarUp}
                                </div>
                            </BackTop>
                            
                        )
                    }
                    <Outlet />
                </div>
                {
                    isDesktopOrLaptop && (
                        <div className='wk_footer'>
                            <div className='container'>
                                <div className='wk_footer-connent'>
                                    <div className='row'>
                                        <div className='col-9'>
                                            <div className='wk_footer-connent-menu'>
                                                <div className='connent-menu-item'>
                                                    <h6>{t('footer.home')}</h6>
                                                    <ul>
                                                        <li><span onClick={() => scrollToAnchor("w-top", "/")}>{t('footer.home_sub_corporate')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-fwnr", "/")}>{t('footer.home_sub_service')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-hxsj", "/")}>{t('footer.home_sub_core')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-hzmt", "/")}>{t('footer.home_sub_media')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-fukh", "/")}>{t('footer.home_sub_serving')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-fzlc", "/")}>{t('footer.home_sub_development')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-qyry", "/")}>{t('footer.home_sub_honors')}</span></li>
                                                        {/* <li><span onClick={() => scrollToAnchor("w-dsh", "/")}>{t('footer.home_sub_board')}</span></li> */}
                                                    </ul>
                                                </div>
                                                <div className='connent-menu-item'>
                                                    <h6>{t('footer.business')}</h6>
                                                    <ul>
                                                        <li><span onClick={() => scrollToAnchor("w-bussiness", "/business?type=0")}>{t('footer.business_sub_hardcore')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-bussiness", "/business?type=1")}>{t('footer.business_sub_quick')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-bussiness", "/business?type=2")}>{t('footer.business_sub_golden')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-bussiness", "/business?type=3")}>{t('footer.business_sub_ads')}</span></li>
                                                    </ul>
                                                </div>
                                                <div className='connent-menu-item'>
                                                    <h6>{t('footer.news')}</h6>
                                                    <ul>
                                                        {
                                                            categories.map((item, index)=> {
                                                                return (
                                                                    <li><span onClick={() => scrollToAnchor("w-news", `/news?active=${index}`, index)}>{item.name}</span></li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                                <div className='connent-menu-item'>
                                                    <h6>{t('footer.investor')}</h6>
                                                    <ul>
                                                        <li><span onClick={() => scrollToAnchor("w-ljs", "/investor")}>{t('footer.investor_sub_stock')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-qygz", "/investor")}>{t('footer.investor_sub_corporate')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-gszc", "/investor")}>{t('footer.investor_sub_company')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-cwxwg", "/investor")}>{t('footer.investor_sub_financial')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-gsgg", "/investor")}>{t('footer.investor_sub_announcements')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-zgwj", "/investor")}>{t('footer.investor_sub_prospectus')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-cwbg", "/investor")}>{t('footer.investor_sub_reports')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-tzzgxdt", "/investor")}>{t('footer.investor_sub_event')}</span></li>
                                                    </ul>
                                                </div>
                                                <div className='connent-menu-item'>
                                                    <h6>{t('footer.jobs')}</h6>
                                                    <ul>
                                                        <li><span onClick={() => scrollToAnchor("w-bghj", "/jobs")}>{t('footer.jobs_sub_office')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-ygfl", "/jobs")}>{t('footer.jobs_sub_employe')}</span></li>
                                                        <li><span onClick={() => scrollToAnchor("w-zpxx", "/jobs")}>{t('footer.jobs_sub_recruitment')}</span></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-3 textAlignRight'>
                                            <div className='wk_footer-connent-qr'>
                                                <img src={qrPath} alt='qr' />
                                                <p>{t('footer.qr_text')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='wk_footer-contact'>
                                    <h6>{t('footer.contact_us')}</h6>
                                    <div className='wk_footer-contact-list'>
                                        <div className='contact-list-item'>
                                            <div className='contact-list-item-flex'>
                                                <span>{geoAlt}</span>
                                                <p>{t('footer.contact_us_local_bj')}</p>
                                            </div>
                                            <div className='contact-list-item-flex'>
                                                <span className='telephone'>{telephone}</span>
                                                <p>010-64820535</p>
                                            </div>
                                        </div>
                                        <div className='contact-list-item'>
                                            <div className='contact-list-item-flex'>
                                                <span>{geoAlt}</span>
                                                <p>{t('footer.contact_us_local_gz')}</p>
                                            </div>
                                            <div className='contact-list-item-flex'>
                                            <span className='telephone'>{telephone}</span>
                                            <p>010-64820535</p>
                                        </div>
                                        </div>
                                        <div className='contact-list-item'>
                                            <div className='contact-list-item-flex'>
                                                <span>{geoAlt}</span>
                                                <p>{t('footer.contact_us_local_sz')}</p>
                                            </div>
                                            <div className='contact-list-item-flex'>
                                                <span className='telephone'>{telephone}</span>
                                                <p>0755-23358717</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='wk_footer-copyright'>
                                    <p>
                                    {/* Copyright © 1998 - 2024 Wanka Online Inc.All Right Reserved.Wanka Online Inc. 版權所有  ｜  京网文（2016）2512-294号 | 京ICP备14038000号-21 | 京公网安备11010502036769 */}
                                        Copyright © 1998 - 2024 Wanka Online Inc.All Right Reserved.Wanka Online Inc. 版權所有 | 京ICP备14038000号-21
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                }
                {
                    !isDesktopOrLaptop && (
                        <div className='m-wk_footer'>
                            <div className='container'>
                                <div className='m-wk_footer-contact'>
                                    <h6>{t('footer.contact_us')}</h6>
                                    <div className='wk_footer-contact-list'>
                                        <div className='contact-list-item'>
                                            <div className='contact-list-item-flex'>
                                                <span>{geoAlt}</span>
                                                <p>{t('footer.contact_us_local_bj')}</p>
                                            </div>
                                            <div className='contact-list-item-flex'>
                                                <span className='telephone'>{telephone}</span>
                                                <p>010-64820535</p>
                                            </div>
                                        </div>
                                        <div className='contact-list-item'>
                                            <div className='contact-list-item-flex'>
                                                <span>{geoAlt}</span>
                                                <p>{t('footer.contact_us_local_gz')}</p>
                                            </div>
                                            <div className='contact-list-item-flex'>
                                            <span className='telephone'>{telephone}</span>
                                            <p>010-64820535</p>
                                        </div>
                                        </div>
                                        <div className='contact-list-item'>
                                            <div className='contact-list-item-flex'>
                                                <span>{geoAlt}</span>
                                                <p>{t('footer.contact_us_local_sz')}</p>
                                            </div>
                                            <div className='contact-list-item-flex'>
                                                <span className='telephone'>{telephone}</span>
                                                <p>0755-23358717</p>
                                            </div>
                                        </div>
                                        <div className='contact-list-item'>
                                            <div className='m-wk_footer-connent-qr'>
                                                <img src={qrPath} alt='qr' />
                                                <p>{t('footer.qr_text')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='wk_footer-copyright'>
                                    <p>
                                    {/* Copyright © 1998 - 2024 Wanka Online Inc.All Right Reserved.Wanka Online Inc. 版權所有  ｜  京网文（2016）2512-294号 | 京ICP备14038000号-21 | 京公网安备11010502036769 */}
                                        Copyright © 1998 - 2024 Wanka Online Inc.All Right Reserved.Wanka Online Inc. 版權所有 | 京ICP备14038000号-21
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                }
                <Drawer
                    title=""
                    closable={false}
                    onClose={onClose}
                    width={'100%'}
                    open={open}
                    className="m-drawer"
                >
                    <div className='m-drawer-menu'>
                        <div className='m-header-wrapper'>
                            <div className='container m-header-content'>
                                <div className='m-wk_logo'></div>
                                <div className='m-wk_translate'>
                                    <span className={`m-wk_translate_item ${lang == 1 ? 'active' : ''}`} onClick={() => changLange(1)}>简</span>
                                    <span className={`m-wk_translate_item ${lang == 2 ? 'active' : ''}`} onClick={() => changLange(2)}>繁</span>
                                    <span className={`m-wk_translate_item ${lang == 3 ? 'active' : ''}`} onClick={() => changLange(3)}>En</span>
                                    <div style={{color: '#fff'}} onClick={onClose}>{InlineLayoutBi}</div>
                                </div>
                            </div>
                        </div>
                        <div className='m-collapse'>
                            <Collapse accordion expandIconPosition="end">
                                <Panel header={t('footer.home')} key="1">
                                    <ul>
                                        <li onClick={() => mscrollToAnchor("w-top", "/")}><span>{t('footer.home_sub_corporate')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-fwnr", "/")}><span>{t('footer.home_sub_service')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-hxsj", "/")}><span>{t('footer.home_sub_core')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-hzmt", "/")}><span>{t('footer.home_sub_media')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-fukh", "/")}><span>{t('footer.home_sub_serving')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-fzlc", "/")}><span>{t('footer.home_sub_development')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-qyry", "/")}><span>{t('footer.home_sub_honors')}</span></li>
                                        {/* <li onClick={() => mscrollToAnchor("w-dsh", "/")}><span>{t('footer.home_sub_board')}</span></li> */}
                                    </ul>
                                </Panel>
                                <Panel header={t('footer.business')} key="2">
                                    <ul>
                                        <li onClick={() => mscrollToAnchor("w-bussiness", "/business?type=0")}><span>{t('footer.business_sub_hardcore')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-bussiness", "/business?type=1")}><span>{t('footer.business_sub_quick')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-bussiness", "/business?type=2")}><span>{t('footer.business_sub_golden')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-bussiness", "/business?type=3")}><span>{t('footer.business_sub_ads')}</span></li>
                                    </ul>
                                </Panel>
                                <Panel header={t('footer.news')} key="3">
                                    <ul>
                                        {
                                            categories.map((item, index)=> {
                                                return (
                                                    <li><span onClick={() => mscrollToAnchor("w-news", `/news?active=${index}`, index)}>{item.name}</span></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </Panel>
                                <Panel header={t('footer.investor')} key="4">
                                    <ul>
                                        <li onClick={() => mscrollToAnchor("w-ljs", "/investor")}><span>{t('footer.investor_sub_stock')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-qygz", "/investor")}><span>{t('footer.investor_sub_corporate')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-gszc", "/investor")}><span>{t('footer.investor_sub_company')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-cwxwg", "/investor")}><span>{t('footer.investor_sub_financial')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-gsgg", "/investor")}><span>{t('footer.investor_sub_announcements')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-zgwj", "/investor")}><span>{t('footer.investor_sub_prospectus')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-cwbg", "/investor")}><span>{t('footer.investor_sub_reports')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-tzzgxdt", "/investor")}><span>{t('footer.investor_sub_event')}</span></li>
                                    </ul>
                                </Panel>
                                <Panel header={t('footer.jobs')} key="5">
                                    <ul>
                                        <li onClick={() => mscrollToAnchor("w-bghj", "/jobs")}><span>{t('footer.jobs_sub_office')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-ygfl", "/jobs")}><span>{t('footer.jobs_sub_employe')}</span></li>
                                        <li onClick={() => mscrollToAnchor("w-zpxx", "/jobs")}><span>{t('footer.jobs_sub_recruitment')}</span></li>
                                    </ul>
                                </Panel>
                            </Collapse>
                        </div>
                    </div>
                </Drawer>
            </div>
        </MyContext.Provider>
    )
}

export default UserLayout

export function useMyContext() {
    return useContext(MyContext);
}