import { useSpring, animated, useInView  } from 'react-spring';
import React, {  useEffect, useState } from 'react'
const AnimatedNum = ({ value, duration = 1000, classNameValue }) =>{
  const [ref, inView] = useInView();
  const [ show, setShow ] = useState(false)
  useEffect(()=> {
    if (inView) {
      setShow(true)
    }
  },[inView])
  
  return (
    <span className={classNameValue} ref={ref}>
      { show? <AnimatedBox value={value} duration={duration} />: null }
    </span>
   
  )
}
const AnimatedBox = ({value, duration})=> {
  const { number } = useSpring({
      from: { number: 0 },
      to: { number: value },
      config: { duration: duration },
  });
  return (
    <animated.span   >
        {number.interpolate((val) => {
          const v = Math.floor(val);
          return v=== Math.floor(value)? value : v;
        } )}
    </animated.span>
  )
}
export default AnimatedNum;