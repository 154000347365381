/**
 * 过滤掉null undefined的字段
 * @param {*} obj 
 * @returns 
 */
export function clearObj(obj) {
  const o = {};
  Object.keys(obj).map(v=> {
    if (!(obj[v] === null || obj[v] === undefined)) {
      o[v] = obj[v];
    }
  })
  return o;
}
/**
 * 格式化日期
 * @param {*} time 
 * @param {*} cFormat 
 * @returns 
 */
export function formatDate(time, cFormat) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string')) {
      if ((/^[0-9]+$/.test(time))) {
        // support "1548221490638"
        time = parseInt(time)
      } else {
        // support safari
        // https://stackoverflow.com/questions/4310953/invalid-date-in-safari
        time = time.replace(new RegExp(/-/gm), '/')
      }
    }

    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value ] }
    return value.toString().padStart(2, '0')
  })
  return time_str
};
/**
 * 下载文件
 * @param {*} url 
 * @param {*} filename 
 * @returns 
 */
export async function downloadFile(api, filename) {
  // saveAs(api, filename)
}
/**
 * 获取地址链接参数
 * @returns 
 */
export function getParamsUrl() {
  // url获取参数查询
let params_url = window.location.href.split('?')
let params_arr = null
let obj = {};
if (params_url.length > 1) {
    params_arr = params_url[1].split('&')
    params_arr.forEach((element) => {
        let kv = element.split('=')
        obj[kv[0]] = kv[1]
    })
};
return obj
};