import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import routes from './routes';
import ScrollToTop from './scrollToTop'

const router = () => {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                {
                    routes.map((item) => {
                        return (
                            <Route path={item.path} key={item.id} element={<item.component />}>
                                {
                                    item.children ? item.children.map((item_c)=>{
                                        return (
                                            <Route path={item_c.path} key={item_c.id} element={<item_c.component />}></Route>
                                        )
                                    }) : null
                                }
                                <Route path="*" element={<Navigate to="/home"/>}/>
                            </Route>
                        )
                    })
                }
            </Routes>
        </BrowserRouter>
    );
};

export default router;