import { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import router from './router'
import logo from './logo.svg';

const lngs = {
  en: { nativeName: 'English' },
  zh: { nativeName: '中文' },
  hk: { nativeName: '繁体' }
};

function App() {
  const { t, i18n } = useTranslation();
  return (
    <div className="App">
      {/* <a to="/home">首页</a>
      <a to="/about">关于我们</a>
      <header className="App-header">
        <select onChange={(evt) => {
          i18n.changeLanguage(evt.target.value)
        }}>
          {Object.keys(lngs).map((lng) => (
            <option key={lng} value={lng} label={lngs[lng].nativeName}
              style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} />
          ))}
        </select>
        <p>
          {t('welcome')}
        </p>
        <Trans i18nKey="author">
          Hello <strong></strong>
        </Trans>
      </header> */}
      {router()}
    </div>
  );
}

export default function WrappedApp() {
  return (
    <Suspense fallback="loading">
      <App />
    </Suspense>
  );
}
