import axios from 'axios'
import { message } from 'antd';
import qs from 'qs'
import { Modal } from 'antd';
const logout = () => {
    // removeStore('Token')
    // setTimeout(()=>{router.push({name: 'login'})}, 200)
}
// 当前环境接口请求url  window.CONFIG.API_URL
const ajaxUrl = process.env.NODE_ENV === 'production' ? '' : ''
const pro_withCredentials = process.env.NODE_ENV === 'production' ? false : true
/**
 * ajaxUrl : api的base_url
 * timeout : 请求超时时间 5s
 * withCredentials: 跨域请求时是否带cookie
 **/
let instance = axios.create({
    baseURL: ajaxUrl,
    timeout: 50000,
    withCredentials: pro_withCredentials,
    // headers: {
    //     Cookies: 'PHPSESSID=ksc10n9ssl28dr6usrc97vulv1'
    // }
})
// http状态为200 error_code = 401
const error2Msg = (config, data) => {
    let fetch_url = [
        '/admin/overdue/getListData',
        '/admin/dataAnalysisPhaseIP/dataByKeyIndicators',
        '/admin/dataAnalysisPhaseIP/accountBalanceFluctuationChart',
        '/admin/dataAnalysisPhaseIP/consumableFluctuationChart',
        '/admin/accountOverviewApi/searchData',
        '/admin/accountOverviewApi/searchCustomerData',
        '/admin/accountOverviewApi/getList',
        '/admin/accountOverviewApi/FOMSearchData',
        '/admin/accountOverviewApi/FOMSearchCustomerData ',
        '/admin/accountOverviewApi/getFOMOverview',
        '/admin/accountOverviewApi/getFOMList',
        '/admin/pgc/payable/preCreatePayment',
        'http://umc.gm825.net/api/getMessageCount',
        'https://umc.wankacn.com/api/getMessageCount',
    ] //特殊处理接口  结算中心  账户健康监测

    let isModel = fetch_url.includes(config.url)
    if (isModel) {
        return true
    } else {
        return false
    }
}
const errorHandler = (error) => {
    const { data, config, status } = error;
    let msg = "";
    if (status == 302) {
        window.open('http://auc.gm825.net/login')
        return
    }
    if (!data) {
        // Message.error("您的网络发生异常，无法连接服务器");
        return;
    }
    if (data && data.error_code && data.error_code != 0) {
        msg = data && data.error_msg ? data.error_msg : "没有数据";
        Modal.error({
            title: '提示',
            content: msg,
        });

    }
    return data;
};


// 请求拦拦截器
let loadTimer = null;
instance.interceptors.request.use(
    async config => {
        for (let key in config.params) {
            if (config.params[key] === '' || config.params[key] === null || config.params[key] === undefined) {
                delete config.params[key]
            }
        }
        // if (loadTimer) clearTimeout(loadTimer);
        // if (loadTimer) clearTimeout(loadTimer);
        // //超过10s关闭loading
        // loadTimer = setTimeout(()=>{
        //     store.dispatch('common/updatePageLoading' , { pageLoading: false, });
        // }, 10000);
        // 获取token,添加请求头
        // let token = getStore("Token");
        // let header = {
        //     AUTHORIZATION: "",
        // };
        // if (token != undefined) {
        //     header["AUTHORIZATION"] = token;
        // }
        // Object.assign(config.headers, header);
        return Promise.resolve(config)
    },
    async error => {
        // 关闭loading 动画
        // store.dispatch('common/updatePageLoading' , { pageLoading: false });
        return Promise.reject(error)
    }
)


// 响应拦截器
instance.interceptors.response.use(
    async response => {
        //处理error_code
        await errorHandler(response);
        //关闭loading 动画
        // store.dispatch('common/updatePageLoading' , { pageLoading: false });
        return Promise.resolve(response);
    },
    async error => {
        // 处理error_code、关闭loading 动画
        await errorHandler(error.response);
        // store.dispatch('common/updatePageLoading' , { pageLoading: false });
        return Promise.reject(error);
    }
)
/**
 * get方法封装
 **/
export async function Get(url, params, headers=null) {
    // store.dispatch('common/updatePageLoading' , { pageLoading: true });
    //获取返回promise
    let result = instance.get(url, {
        params: params,
        headers:headers
        // headers:{
        //     'Content-Type': 'application/vnd.ms-excel'
        // }
    }).then(res => res.data);
    return result;
}
/**
 * post方法封装
 **/
export async function Post(url, data, params = {}, headers={}) {
    // store.dispatch('common/updatePageLoading' , { pageLoading: true });
    //序列化数据
    // let setdata = qs.stringify(data);
    //获取返回promise
    let setdata = null
    // if(url != '/upload/img?type=1') {
    //     setdata = qs.stringify(data);
    // }else{
    //     setdata = data
    // }
    setdata = qs.stringify(data)
    let setparams = Object.assign({}, { headers: { 'Content-Type': 'application/x-www-form-urlencoded', ...headers } }, params)
    let result = instance.post(url, setdata, setparams).then(res => res.data);
    return result;
}



