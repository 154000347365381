import {Get, Post} from '../libs/axios'
const proxy = process.env.NODE_ENV === 'development' ? '/test' : ''

// 新闻 新闻列表
export async function GET_NEWS_LIST(data={}) {
    const {error_code, page, list} = await Get(`${proxy}/api/news/list`,{
        ...data
    })
    return {error_code, page, list}
}

// 新闻 详情
export async function GET_NEWS_DETAIL(data={}) {
    const {error_code, detail} = await Get(`${proxy}/api/news/detail`,{
        ...data
    })
    return {error_code, detail}
}
// 查询新闻类型
export async function GET_NEWS_CATEGORY(data={}) {
   return await Get(`${proxy}/api/news/category`,{ ...data })
}
// 招聘 办公场所照片
export async function GET_JOBS_PHOTO(data={}) {
    const {error_code, list} = await Get(`${proxy}/api/join/index`,{
        language:data.language,
    })
    return {error_code, list}
}


// 招聘 列表
export async function GET_JOBS_LIST(data={}) {
    const {error_code, page, list, cityList, positionList} = await Get(`${proxy}/api/join/list`,{
        ...data
    })
    return {error_code, page, list, cityList, positionList}
}

// 公司 发展历程
export async function GET_INFO_DEVELOPMENT(data={}) {
    const {error_code, list} = await Get(`${proxy}/api/info/development`,{
        language:data.language
    })
    return {error_code, list}
}


//投资者关系  列表

export async function GET_INV_BRIEF(data={}) {
    const {error_code, page, list} = await Get(`${proxy}/api/investment/brief`,{
        language:data.language
    })
    return {error_code, page, list}
}

//投资者关系  公司章程

export async function GET_INV_REGU(data={}) {
    const {error_code, list} = await Get(`${proxy}/api/investment/regulations`,{
        ...data
    })
    return {error_code, list}
}


//投资者关系  财务章程

export async function GET_INV_FINANCIAL(data={}) {
    const {error_code, list} = await Get(`${proxy}/api/investment/financialnews`,{
        ...data
    })
    return {error_code, list}
}

//投资者关系  公司公告

export async function GET_INV_COMPANY(data={}) {
    const {error_code, list} = await Get(`${proxy}/api/investment/companynotice`,{
        ...data
    })
    return {error_code, list}
}

//投资者关系  公司公告

export async function GET_INV_FIN_NOTICE(data={}) {
    const {error_code, list} = await Get(`${proxy}/api/investment/financialnotice`,{
        language:data.language
    })
    return {error_code, list}
}


//投资者关系  投资者关系

export async function GET_INV_INVESTNEWS(data={}) {
    const {error_code, list} = await Get(`${proxy}/api/investment/investnews`,{
        ...data
    })
    return {error_code, list}
}

//投资者关系  招股文件

export async function GET_INV_PROSPECTUS(data={}) {
    const {error_code, list} = await Get(`${proxy}/api/investment/prospectus`,{
        ...data
    })
    return {error_code, list}
}