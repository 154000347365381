import { STATIC_URL } from '../../ip.js'
const oppoImg = `${ STATIC_URL }/imgs/media-item-oppo.png`
 
const vivoImg = `${ STATIC_URL }/imgs/media-item-vivo.png`
const huaweiImg = `${ STATIC_URL }/imgs/media-item-hw.png`
const miImg = `${ STATIC_URL }/imgs/media-item-mi.png`
const appleImg = `${ STATIC_URL }/imgs/media-item-apple.png`
const qiyImg = `${ STATIC_URL }/imgs/media-item-aqy.png`
const honorImg = `${ STATIC_URL }/imgs/media-item-honor.png`
const coolpadImg = `${ STATIC_URL }/imgs/media-item-coolpad.png`
const lenovoImg = `${ STATIC_URL }/imgs/media-item-lenovo.png`
const nubiaImg = `${ STATIC_URL }/imgs/media-item-nubia.png`
const meizuImg = `${ STATIC_URL }/imgs/media-item-meizu.png`
const ydImg = `${ STATIC_URL }/imgs/media-item-yd.png`
const zteImg = `${ STATIC_URL }/imgs/media-item-zte.png`
const bbgImg = `${ STATIC_URL }/imgs/media-item-bbg.png`

const customImg1 = `${ STATIC_URL }/imgs/custom/custom-1.png`
const customImg2 = `${ STATIC_URL }/imgs/custom/custom-2.png`
const customImg3 = `${ STATIC_URL }/imgs/custom/custom-3.png`
const customImg4 = `${ STATIC_URL }/imgs/custom/custom-4.png`
const customImg5 = `${ STATIC_URL }/imgs/custom/custom-5.png`
const customImg6 = `${ STATIC_URL }/imgs/custom/custom-6.png`
const customImg7 = `${ STATIC_URL }/imgs/custom/custom-7.png`
const customImg8 = `${ STATIC_URL }/imgs/custom/custom-8.png`
const customImg9 = `${ STATIC_URL }/imgs/custom/custom-9.png`
const customImg10 = `${ STATIC_URL }/imgs/custom/custom-10.png`
const customImg11 = `${ STATIC_URL }/imgs/custom/custom-11.png`

const customImg12 = `${ STATIC_URL }/imgs/custom/custom-12.png`
const customImg13 = `${ STATIC_URL }/imgs/custom/custom-13.png`
const customImg14 = `${ STATIC_URL }/imgs/custom/custom-14.png`
const customImg15 = `${ STATIC_URL }/imgs/custom/custom-15.png`
const customImg16 = `${ STATIC_URL }/imgs/custom/custom-16.png`
const customImg17 = `${ STATIC_URL }/imgs/custom/custom-17.png`
const customImg18 = `${ STATIC_URL }/imgs/custom/custom-18.png`
const customImg19 = `${ STATIC_URL }/imgs/custom/custom-19.png`
const customImg20 = `${ STATIC_URL }/imgs/custom/custom-20.png`
const customImg21 = `${ STATIC_URL }/imgs/custom/custom-21.png`
const customImg22 = `${ STATIC_URL }/imgs/custom/custom-22.png`

const customImg23 = `${ STATIC_URL }/imgs/custom/custom-23.png`
const customImg24 = `${ STATIC_URL }/imgs/custom/custom-24.png`
const customImg25 = `${ STATIC_URL }/imgs/custom/custom-25.png`
const customImg26 = `${ STATIC_URL }/imgs/custom/custom-26.png`
const customImg27 = `${ STATIC_URL }/imgs/custom/custom-27.png`
const customImg28 = `${ STATIC_URL }/imgs/custom/custom-28.png`
const customImg29 = `${ STATIC_URL }/imgs/custom/custom-29.png`
const customImg30 = `${ STATIC_URL }/imgs/custom/custom-30.png`
const customImg31 = `${ STATIC_URL }/imgs/custom/custom-31.png`
const customImg32 = `${ STATIC_URL }/imgs/custom/custom-32.png`
const customImg33 = `${ STATIC_URL }/imgs/custom/custom-33.png`

const customImg34 = `${ STATIC_URL }/imgs/custom/custom-34.png`
const customImg35 = `${ STATIC_URL }/imgs/custom/custom-35.png`
const customImg36 = `${ STATIC_URL }/imgs/custom/custom-36.png`
const customImg37 = `${ STATIC_URL }/imgs/custom/custom-37.png`
const customImg38 = `${ STATIC_URL }/imgs/custom/custom-38.png`
const customImg39 = `${ STATIC_URL }/imgs/custom/custom-39.png`
const customImg40 = `${ STATIC_URL }/imgs/custom/custom-40.png`
const customImg41 = `${ STATIC_URL }/imgs/custom/custom-41.png`
const customImg42 = `${ STATIC_URL }/imgs/custom/custom-42.png`
const customImg43 = `${ STATIC_URL }/imgs/custom/custom-43.png`
const customImg44 = `${ STATIC_URL }/imgs/custom/custom-44.png`


const honorItemImg1 = `${ STATIC_URL }/imgs/honor/honor-item-1.png`
const honorItemImg2 = `${ STATIC_URL }/imgs/honor/honor-item-16.png`
const honorItemImg3 = `${ STATIC_URL }/imgs/honor/honor-item-3.png`
const honorItemImg4 = `${ STATIC_URL }/imgs/honor/honor-item-4.png`
const honorItemImg5 = `${ STATIC_URL }/imgs/honor/honor-item-5.png`
const honorItemImg6 = `${ STATIC_URL }/imgs/honor/honor-item-6.png`
const honorItemImg7 = `${ STATIC_URL }/imgs/honor/honor-item-7.png`
const honorItemImg8 = `${ STATIC_URL }/imgs/honor/honor-item-8.png`
const honorItemImg9 = `${ STATIC_URL }/imgs/honor/honor-item-9.png`
const honorItemImg10 = `${ STATIC_URL }/imgs/honor/honor-item-10.png`
const honorItemImg11 = `${ STATIC_URL }/imgs/honor/honor-item-11.png`
const honorItemImg12 = `${ STATIC_URL }/imgs/honor/honor-item-12.png`
const honorItemImg13 = `${ STATIC_URL }/imgs/honor/honor-item-13.png`
const honorItemImg14 = `${ STATIC_URL }/imgs/honor/honor-item-15.png`
const honorItemImg15 = `${ STATIC_URL }/imgs/honor/honor-item-14.png`
const honorItemImg16 = `${ STATIC_URL }/imgs/honor/honor-item-2.png`

const businessItem1 = `${ STATIC_URL }/imgs/business-item-1.png`
const businessItem2 = `${ STATIC_URL }/imgs/business-item-2.png`
const businessItem3 = `${ STATIC_URL }/imgs/business-item-3.png`
const businessItem4 = `${ STATIC_URL }/imgs/business-item-4.png`
const businessItem5 = `${ STATIC_URL }/imgs/business-item-5.png`
const businessItem6 = `${ STATIC_URL }/imgs/business-item-6.png`
const businessItem7 = `${ STATIC_URL }/imgs/business-item-7.png`
const businessItem8 = `${ STATIC_URL }/imgs/business-item-8.png`

const shenzhen = `${ STATIC_URL }/imgs/shenzhen.jpg`
const beijing = `${ STATIC_URL }/imgs/beijing.jpg`
const guangzhou = `${ STATIC_URL }/imgs/guangzhou.jpg`
const environmenItem1 = `${ STATIC_URL }/imgs/environmen-item_1.jpg`
const environmenItem2 = `${ STATIC_URL }/imgs/environmen-item_2.jpg`
const environmenItem3 = `${ STATIC_URL }/imgs/environmen-item_3.jpg`
const environmenItem4 = `${ STATIC_URL }/imgs/environmen-item_4.jpg`
const environmenItem5 = `${ STATIC_URL }/imgs/environmen-item_5.jpg`
const environmenItem6 = `${ STATIC_URL }/imgs/environmen-item_6.jpg`
const environmenItem7 = `${ STATIC_URL }/imgs/environmen-item_7.jpg`
const environmenItem8 = `${ STATIC_URL }/imgs/environmen-item_8.jpg`

const environmenGz1 = `${ STATIC_URL }/imgs/environmen-gz_1.jpg`
const environmenGz2 = `${ STATIC_URL }/imgs/environmen-gz_2.jpg`
const environmenGz3 = `${ STATIC_URL }/imgs/environmen-gz_3.jpg`
const environmenGz4 = `${ STATIC_URL }/imgs/environmen-gz_4.jpg`
const environmenGz5 = `${ STATIC_URL }/imgs/environmen-gz_5.jpg`
const environmenGz6 = `${ STATIC_URL }/imgs/environmen-gz_6.jpg`
const environmenGz7 = `${ STATIC_URL }/imgs/environmen-gz_7.jpg`
const environmenGz8 = `${ STATIC_URL }/imgs/environmen-gz_8.jpg`


const environmenSz1 = `${ STATIC_URL }/imgs/environmen-sz_1.jpg`
const environmenSz2 = `${ STATIC_URL }/imgs/environmen-sz_2.jpg`
const environmenSz3 = `${ STATIC_URL }/imgs/environmen-sz_3.jpg`
const environmenSz4 = `${ STATIC_URL }/imgs/environmen-sz_4.jpg`
const environmenSz5 = `${ STATIC_URL }/imgs/environmen-sz_5.jpg`
const environmenSz6 = `${ STATIC_URL }/imgs/environmen-sz_6.jpg`
const environmenSz7 = `${ STATIC_URL }/imgs/environmen-sz_7.jpg`
const environmenSz8 = `${ STATIC_URL }/imgs/environmen-sz_8.jpg`

const flItem1 = `${ STATIC_URL }/imgs/fl-item-1.png`
const flItem2 = `${ STATIC_URL }/imgs/fl-item-2.png`
const flItem3 = `${ STATIC_URL }/imgs/fl-item-3.png`
const flItem4 = `${ STATIC_URL }/imgs/fl-item-4.png`
const flItem5 = `${ STATIC_URL }/imgs/fl-item-5.png`
const flItem6 = `${ STATIC_URL }/imgs/fl-item-6.png`
const flItem7 = `${ STATIC_URL }/imgs/fl-item-7.png`
const flItem8 = `${ STATIC_URL }/imgs/fl-item-8.png`
const flItem9 = `${ STATIC_URL }/imgs/fl-item-9.png`
const flItem10 = `${ STATIC_URL }/imgs/fl-item-10.png`

export const LogoImg = {
    oppoImg,
    vivoImg,
    huaweiImg,
    miImg,
    appleImg,
    qiyImg,
    honorImg,
    coolpadImg,
    lenovoImg,
    nubiaImg,
    meizuImg,
    ydImg,
    zteImg,
    bbgImg
} 
export const imagePaths = [
    customImg1,customImg2,customImg3,customImg4,customImg5,customImg6,customImg7,customImg8,customImg9,customImg10,customImg11,
    customImg12,customImg13,customImg14,customImg15,customImg16,customImg17,customImg18,customImg19,customImg20,customImg21,customImg22,
    customImg23,customImg24,customImg25,customImg26,customImg27,customImg28,customImg29,customImg30,customImg31,customImg32,customImg33,
    customImg34,customImg35,customImg36,customImg37,customImg38,customImg39,customImg40,customImg41,customImg42,customImg43,customImg44
];

export const imageHonorPaths = {
    honorItemImg1,honorItemImg2,honorItemImg3,honorItemImg4,honorItemImg5,honorItemImg6,
    honorItemImg7,honorItemImg8,honorItemImg9,honorItemImg10,honorItemImg11,honorItemImg12,
    honorItemImg13,honorItemImg14,honorItemImg15,honorItemImg16
};

export const imageBusinessPaths = {
    businessItem1,businessItem2,businessItem3,businessItem4,businessItem5,businessItem6, businessItem7, businessItem8
};

export const imageJobPaths= {
    0: [ environmenGz1, environmenGz2, environmenGz3,environmenGz4,environmenGz5,environmenGz6,environmenGz7,environmenGz8 ],
    1: [environmenItem1,environmenItem2,environmenItem3,environmenItem4,environmenItem5,environmenItem6,environmenItem7,environmenItem8],
    2: [environmenSz1, environmenSz2, environmenSz3,environmenSz4,environmenSz5,environmenSz6,environmenSz7,environmenSz8   ],
    shenzhen, beijing, guangzhou,
    flItem1,flItem2,flItem3,flItem4,flItem5,flItem6,flItem7,flItem8,flItem9,flItem10
}